export default {
  state: {
    clickPath: [],
  },

  mutations: {
    setClickPath: (state, payload) => {
      state.clickPath = payload;
      return state;
    },

    addClickPath: (state, payload) => {
      state.clickPath.push(payload);
      return state;
    },
  },
};
