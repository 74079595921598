import imageConstants from "~/util/imageConstants";

export const CONSTANTS = {
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  images: imageConstants,
  bookingHash: "booking",
  seo: {
    defaultTitle: "South Africa's Premier Luxury Safari Lodge | Londolozi Game Reserve",
    defaultDescription: 'Londolozi combines luxury and conservation for an unforgettable African safari experience. Personifying the Zulu meaning "Protector of all things".',
    londoloziSuffix: "| Londolozi Game Reserve",
    defaultImage: "https://cdn.londolozi.com/wp-content/uploads/2020/08/crossing-scaled.jpg",
    stripTagsReg: /<\/?[^>]+(>|$)/g,
  }
};
