import imageConstants from "~/util/imageConstants";

export const CONSTANTS = {
  trackOnNextTick: false,
  images: imageConstants,
  bookingHash: "booking",
  sharpspring: {
    scriptSrc:
      "https://koi-3QNFJS5T58.marketingautomation.services/client/noform.js?ver=1.24",
    baseURI:
      "https://app-3QNFJS5T58.marketingautomation.services/webforms/receivePostback/MzawMDGzNDMxAQA/",
    forms: {
      // Agent Enquiry Form
      enquiryAgent: {
        endpoint: "12da7d68-3ee6-4853-bfb4-42252092b755",
        formId: "contact-form-agent-enquire",
      },

      // General Enquiry Form
      enquiryGuest: {
        endpoint: "1db4f22c-96dd-4a5f-a823-fa0696960042",
        formId: "contact-form-enquire",
      },

      // Newsletter Subscribe
      newsletter: {
        endpoint: "ace9f3a9-09c7-4df0-9e0e-b98e2db0e892",
        formId: "newsletter-form",
      },

      // Tour Enquiry Form
      tour: {
        endpoint: "34b7ea40-5054-4c04-9ba7-26a303946c4a",
        formId: "tour-enquire",
      },

      // Tour Enquiry Form
      booking: {
        endpoint: "75abf2a6-8592-4972-91b7-cd8b2af46eb9",
        formId: "booking-enquiry",
      },

      // Retreat Enquiry Form
      retreat: {
        endpoint: "d27165d1-69d2-4148-899a-2baa4420ce70",
        formId: "retreat-enquire",
      },
    },
  }
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide("constants", CONSTANTS);
});
