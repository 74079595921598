export default {
  sizes: {
    banner:
      "(orientation: portrait) and (max-width: 640px) 788px, (orientation: portrait) and (max-width: 1024px) 1386px, (orientation: portrait) and (max-width: 1280px) 1460px, (orientation: portrait) and (max-width: 1440px) 1940px, (orientation: portrait) and (min-width: 1940px) 2580px, 100vw",
    bottom: "100vw",
    top: "(orientation: portrait) and (max-width: 500px) 660px, (orientation: portrait) and (max-width: 600px) 788px, (orientation: portrait) and (max-width: 1024px) 1386px, (orientation: portrait) and (max-width: 1440px) 1940px, 2580px",
    inContentGallery:
      "(max-width: 434px) 434px, (max-width: 788px) 100vw, 788px",
  },
  logo: {
    menu: "https://cdn.londolozi.com/wp-content/uploads/2020/08/londolozi-logo-menu.png",
    header: {
      text: "https://cdn.londolozi.com/wp-content/uploads/2020/10/londolozi-logo-text-220.png",
    },
    svg: {
      white:
        "https://cdn.londolozi.com/wp-content/uploads/2020/09/londolozi-logo-white.svg",
      black:
        "https://cdn.londolozi.com/wp-content/uploads/2020/09/londolozi-logo-black.svg",
      text: "https://cdn.londolozi.com/wp-content/uploads/2020/09/londolozi-logo-black-text.svg",
      textMain:
        "https://cdn.londolozi.com/wp-content/uploads/2020/09/londolozi-logo-black-text-main.svg",
    },
  },
  leopardWatermark:
    "https://cdn.londolozi.com/wp-content/uploads/2020/08/leopard-watermark.jpg",
  pattern: {
    card: "https://cdn.londolozi.com/wp-content/uploads/2020/09/pattern-card7.png",
    banner:
      "https://cdn.londolozi.com/wp-content/uploads/2020/09/pattern-left-radial-blue2.png",
  },
  fallback: {
    card: "https://cdn.londolozi.com/wp-content/uploads/2020/09/Cheetah_Dean-scaled-aspect-ratio-274-376-280x384.jpg",
    videoBox:
      "https://cdn.londolozi.com/wp-content/uploads/2020/08/Cheetah-434x265.jpg",
    banner: {
      src: "https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-1460x973.jpg",
      srcset:
        "https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-788x525.jpg 788w, https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-1386x924.jpg 1386w, https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-280x187.jpg 280w, https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-434x289.jpg 434w, https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-660x440.jpg 660w, https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-1460x973.jpg 1460w, https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-1940x1293.jpg 1940w, https://cdn.londolozi.com/wp-content/uploads/2020/08/Mashaba-Female-Leopard-2580x1720.jpg 2580w",
    },
    bottom: {
      src: "https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-1940x1007.jpg",
      srcset:
        "https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-788x409.jpg 788w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-1386x719.jpg 1386w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-280x145.jpg 280w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-434x225.jpg 434w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-660x343.jpg 660w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-1460x758.jpg 1460w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-1940x1007.jpg 1940w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-300x156.jpg 300w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-1024x531.jpg 1024w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-768x399.jpg 768w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion-1536x797.jpg 1536w, https://cdn.londolozi.com/wp-content/uploads/2020/06/lion.jpg 2000w",
    },
  },
};
