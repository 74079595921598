import { ref, computed, watch } from 'vue';
import { useFormatPrice } from '@/composables/useFormatPrice';

export function useCardFace(initialProps, context) {
  const cardContent = ref(initialProps.cardContent);
  const type = ref(initialProps.type || 'page');
  const href = ref(initialProps.href || '#');
  const { formatPrice } = useFormatPrice();

  // data
  const cardLinkHovered = ref(false);

  // computed
  const isPost = computed(() => type.value === 'post');
  const isCamp = computed(() => type.value === 'camp' && cardContent.value.meta);
  const isTourRetreat = computed(() => (type.value === 'tour' || type.value === 'retreat') && cardContent.value.meta);
  const price = computed(() => {
    return cardContent.value.meta && cardContent.value.meta.price
      ? formatPrice(cardContent.value.meta.price)
      : false;
  });

  // methods
  const cardNavigation = (e) => {
    if (isPost.value) return;

    e.preventDefault();
    context.emit('navigate');
  };

  watch(cardLinkHovered, (val) => {
    context.emit('cardLinkHovered', val);
  });

  return {
    cardLinkHovered,
    isPost,
    isCamp,
    isTourRetreat,
    price,
    cardNavigation,
    cardContent,
    type,
    href
  };
}
