const scrollableElementId = 'app-content';
const scrollPositions = Object.create(null);

export default {
  scrollBehavior(to, from, savedPosition) {
    const element = document.getElementById(scrollableElementId);
    if (to.path !== from.path && element !== null) {
      const currentPosition = element.scrollTop;
      console.log('%c%s', 'color:hotpink;', 'scrollBehavior: currentPosition is', currentPosition);

      if (savedPosition && to.path in scrollPositions) {
        console.log(
          '%c%s',
          'color:hotpink;',
          'scrollBehavior: navigating to history entry, scroll to saved position',
          scrollPositions[to.path],
        );
        setTimeout(() => {
          element.scrollTop = scrollPositions[to.path];
        }, 600);
      } else {
        console.log('%c%s', 'color:hotpink;', 'navigating to new history entry, scroll to top', 0);
        element.scrollTop = 0;
      }
    }
  },
}
