import { createStore } from "vuex";

import blog from "./modules/blog";
import booking from "./modules/booking";
import cards from "./modules/cards";
import enquiry from "./modules/enquiry";
import faq from "./modules/faq";
import global from "./modules/global";
import modifiers from "./modules/modifiers";
import pages from "./modules/pages";
import session from "./modules/session";

const store = createStore({
  modules: {
    blog,
    booking,
    cards,
    enquiry,
    faq,
    global,
    modifiers,
    session,
    pages,
  },
});

export default store;
