const FAQ_INDEX_KEY = "faqs_index";

export default {
  state: {
    pages: {},
  },

  mutations: {
    setPages: (state, payload) => {
      state.pages = { ...state.pages, ...payload };
      return state;
    },
  },

  actions: {
    setPageData: ({ commit }, { id, payload }) => {
      if (payload[FAQ_INDEX_KEY]) {
        commit(
          "setResourceFaqs",
          { type: payload.type, payload: payload[FAQ_INDEX_KEY] },
          { root: true },
        );
      }

      commit("setPages", { [id]: payload });
    },
  },

  getters: {
    pageById: (state) => (pageId) => state.pages[pageId],
  },
};
