<template>
  <NuxtLink :to="computedPath" v-bind="$attrs">
    <slot></slot>
  </NuxtLink>
</template>

<script>
import { ref, computed, toRefs } from "vue";

export default {
  name: "GlobalLink",
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
  setup(props) {
    const { to } = toRefs(props);

    const computedPath = computed(() => {
      if (
        to.value === "/" ||
        (typeof to.value === "object" && to.value.path === "/")
      ) {
        return to.value;
      }

      if (typeof to.value === "object" && to.value.path) {
        return {
          ...to.value,
          path: `/en${to.value.path}`,
        };
      }

      return `/en${to.value ? (to.value[0] !== "/" ? "/" : "") : ""}${
        to.value
      }`;
    });

    return {
      computedPath,
    };
  },
};
</script>
