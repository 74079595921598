const requiredBlogPostFields = {
  id: "id",
  title: "title",
  excerpt: "excerpt",
  link: "link",
  date: "date",
  thumbnail: "thumbnailImage",
  date_formatted: "dateFormatted",
  author_name: "author",
  author_picture: "authorImage",
  featured_image: "image",
};

export default {
  state: {
    blog: {},
    lastUpdatedAt: null,
  },
  getters: {
    postsByType: (state) => (type) => state.blog[type],
  },
  mutations: {
    setPosts: (state, payload) => {
      const filteredPosts = payload.posts.map((post) => {
        const newPostOb = { type: "post" };

        Object.keys(post).forEach((key) => {
          if (requiredBlogPostFields[key] != null) {
            const value = key === "title" ? post.title.rendered : post[key];

            newPostOb[requiredBlogPostFields[key]] = value;
          }
        });

        return newPostOb;
      });

      state.blog[payload.category] = filteredPosts;

      if (payload.category === "latest") {
        state.lastUpdatedAt = new Date();
      }

      return state;
    },
  },
};
