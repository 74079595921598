<template>
  <londo-swipe-carousel
    v-if="postCards.length"
    :cards="postCards"
    :visible="5"
    :heading="heading"
    :layoutSwitcher="layoutSwitcher"
  />
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { fetchBlogPosts } from "@/api/blog";
import store from "@/store";

export default {
  name: "LondoBlogCarousel",
  setup(props) {
    const postCards = ref([]);

    const categoryPosts = computed(
      () => props.category && Number.isInteger(+props.category),
    );

    const params = computed(() => {
      const paramsData = {
        per_page: props.per_page,
      };
      if (categoryPosts.value) paramsData.categories = props.category;
      return paramsData;
    });

    const fetchAndStorePosts = async () => {
      try {
        const postsType = categoryPosts.value ? props.category : "latest";
        const posts = store.getters.postsByType(postsType);

        if (posts) {
          postCards.value = posts;
        } else {
          const { data } = await fetchBlogPosts(params.value);
          store.commit("setPosts", {
            posts: data,
            category: postsType,
          });
          postCards.value = store.getters.postsByType(postsType);
          setTimeout(() => window.dispatchEvent(new Event("resize")), 300);
        }
      } catch (err) {
        console.error(err);
      }
    };

    onMounted(fetchAndStorePosts);

    return {
      postCards,
      heading: props.heading,
      layoutSwitcher: props.layoutSwitcher,
    };
  },
  props: {
    heading: {
      type: String,
      default: "Latest from the Blog",
    },
    layoutSwitcher: {
      type: Boolean,
      default: false,
    },
    per_page: {
      type: Number,
      default: 6,
    },
    category: {
      type: [Number, String, Boolean],
      default: false,
    },
  },
};
</script>
