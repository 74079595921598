import {
  getHome,
  getAbout,
  getCamps,
  getExperience,
  getSafari,
  getTours,
  getRetreats,
  getConnect,
  getCompare,
  getDestinations,
  getBooking,
  getNotFound,
  getRatesPage,
  getBookingSuccess,
  getImpactPage,
  getEnquirePage,
  pageIdMapping,
} from "@/api/api";

const pageMapping = {
  home: "home",
  about: "en-about___en",
  camps: "en-accommodation___en",
  experience: "en-experience___en",
  safari: "en-safari___en",
  tours: "en-tours___en",
  destinations: "en-destinations___en",
  retreats: "en-retreats___en",
  connect: "en-contact___en",
  rates: "en-accommodation-rates___en",
  compare: "en-accommodation-compare-camps___en",
  enquire: "en-enquire___en",
  booking: "Bookings",
  bookingSuccess: "booking-thank-you___en",
  impact: "en-impact___en",
  notFound: "404___en",
};

const pageToGetter = {
  [pageMapping.home]: getHome,
  [pageMapping.about]: getAbout,
  [pageMapping.camps]: getCamps,
  [pageMapping.experience]: getExperience,
  [pageMapping.safari]: getSafari,
  [pageMapping.tours]: getTours,
  [pageMapping.retreats]: getRetreats,
  [pageMapping.connect]: getConnect,
  [pageMapping.compare]: getCompare,
  [pageMapping.destinations]: getDestinations,
  [pageMapping.booking]: getBooking,
  [pageMapping.bookingSuccess]: getBookingSuccess,
  [pageMapping.notFound]: getNotFound,
  [pageMapping.rates]: getRatesPage,
  [pageMapping.enquire]: getEnquirePage,
  [pageMapping.impact]: getImpactPage,
};

const pageToPageId = {
  [pageMapping.home]: pageIdMapping.home,
  [pageMapping.about]: pageIdMapping.about,
  [pageMapping.camps]: pageIdMapping.camps,
  [pageMapping.rates]: pageIdMapping.rates,
  [pageMapping.experience]: pageIdMapping.experience,
  [pageMapping.safari]: pageIdMapping.safari,
  [pageMapping.tours]: pageIdMapping.tours,
  [pageMapping.retreats]: pageIdMapping.retreats,
  [pageMapping.connect]: pageIdMapping.connect,
  [pageMapping.compare]: pageIdMapping.compare,
  [pageMapping.destinations]: pageIdMapping.destinations,
  [pageMapping.booking]: pageIdMapping.booking,
  [pageMapping.impact]: pageIdMapping.impact,
  [pageMapping.bookingSuccess]: pageIdMapping.bookingSuccess,
  [pageMapping.notFound]: pageIdMapping.notFound,
};

const parentIdToChildRouteName = {
  [pageToPageId[pageMapping.about]]: "AboutSinglePage",
  [pageToPageId[pageMapping.camps]]: "CampSinglePage",
  [pageToPageId[pageMapping.experience]]: "ExperienceSinglePage",
  [pageToPageId[pageMapping.safari]]: "SafariSinglePage",
  [pageToPageId[pageMapping.retreats]]: "RetreatPage",
  [pageToPageId[pageMapping.tours]]: "TourSinglePage",
  [pageToPageId[pageMapping.destinations]]: "DestinationSinglePage",
  [pageToPageId[pageMapping.connect]]: "ConnectSinglePage",
};

const parentIdToIndexRouteName = {
  [pageIdMapping.home]: pageMapping.home,
  [pageIdMapping.about]: pageMapping.about,
  [pageIdMapping.camps]: pageMapping.camps,
  [pageIdMapping.experience]: pageMapping.experience,
  [pageIdMapping.safari]: pageMapping.safari,
  [pageIdMapping.retreats]: pageMapping.retreats,
  [pageIdMapping.tours]: pageMapping.tours,
  [pageIdMapping.impact]: pageMapping.impact,
  [pageIdMapping.destinations]: pageMapping.destinations,
  [pageIdMapping.connect]: pageMapping.connect,
};

const routeNameToPath = {
  [pageMapping.about]: "about",
  [pageMapping.camps]: "accommodation",
  [pageMapping.tours]: "tours",
  [pageMapping.experience]: "experience",
  [pageMapping.safari]: "safari",
  [pageMapping.retreats]: "retreats",
  [pageMapping.connect]: "contact",
  [pageMapping.impact]: "impact",
  AboutSinglePage: "about",
  CampSinglePage: "accommodation",
  ExperienceSinglePage: "experience",
  RetreatPage: "retreats",
  DestinationSinglePage: "destinations",
  TourSinglePage: "tours",
  ConnectSinglePage: "contact",
};

const slugConstants = {
  compare: {
    slug: "compare-camps",
    routeName: pageMapping.compare,
    routePath: routeNameToPath.CampSinglePage,
  },
  rates: {
    slug: "rates",
    routeName: pageMapping.rates,
    routePath: routeNameToPath.CampSinglePage,
  },
  enquire: {
    slug: "enquire",
    routeName: pageMapping.enquire,
    routePath: null,
  },
};

const slugIsConstant = (slug) => {
  const constKeys = Object.keys(slugConstants);

  for (let i = 0; i < constKeys.length; i += 1) {
    const slugObj = slugConstants[constKeys[i]];

    if (slugObj.slug === slug) {
      return slugObj;
    }
  }

  return null;
};

export default pageMapping;

export {
  pageToGetter,
  pageToPageId,
  parentIdToChildRouteName,
  parentIdToIndexRouteName,
  routeNameToPath,
  slugConstants,
  slugIsConstant,
};
