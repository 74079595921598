<!-- eslint-disable max-len -->
<template>
  <a
    :href="href"
    :target="isPost ? '_blank' : '_self'"
    v-bind:rel="isPost ? 'noreferrer' : false"
    @click="cardNavigation"
    class="card__content card__content__link card__front"
    draggable="false"
    ondragstart="return false;"
    :class="[`card__front--${type}`, { 'card__content-blog': isPost }]"
    @mouseover="cardLinkHover()"
    @mouseleave="cardLinkHover(false)"
  >
    <div class="heading" v-html="cardContent.title"></div>
    <div v-if="cardContent.subtitle" class="subheading pb-2">
      {{ cardContent.subtitle }}
    </div>
    <div class="meta meta-card flex" v-if="cardContent.meta">
      <div
        class="meta__attributes flex w-full items-end"
        :class="`meta__attributes-${type}`"
      >
        <div class="attributes flex">
          <div
            v-for="(attribute, index) in attributes"
            :key="index"
            class="meta-attribute"
            :class="`icon-${attribute.value} attribute-${attribute.value}`"
            :title="attribute.label"
            v-tooltip="attributeTooltipOptions(attribute.label)"
            @click="stopPropPreventDefault($event, type === 'camp')"
          >
            <span v-if="type !== 'camp'">{{ attribute.label }}</span>
          </div>
        </div>
        <div
          v-if="price"
          class="meta-price ml-auto flex flex-shrink flex-wrap leading-none items-center text-right justify-end"
        >
          <span class="price-from">From </span
          ><span class="ml-1">R{{ price }}</span>
        </div>
      </div>
    </div>
    <div v-if="isPost" class="card-bottom flex items-center mt-1">
      <div class="card-avatar avatar">
        <img :src="cardContent.authorImage" alt="author image" />
      </div>
      <div class="subheading flex flex-col overflow-hidden">
        <span class="mt-1 xs:mt-0">{{ cardContent.author }}</span>
        <span class="blog-date not-italic">{{
          cardContent.dateFormatted
        }}</span>
      </div>
    </div>
  </a>
</template>

<script>
import { ref, computed } from "vue";
import { useCardFace } from "@/composables/useCardFace";

export default {
  props: {
    cardContent: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "page",
    },
    href: {
      type: String,
      default: "#",
    },
    linkHoverActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const {
      cardLinkHovered,
      isPost,
      isCamp,
      isTourRetreat,
      price,
      cardNavigation,
      cardContent,
      type,
      href,
    } = useCardFace(props, context);

    // data
    const tourRetreatAttributes = ref(["days"]);
    const tooltipOptions = {};

    // computed
    const attributes = computed(() => {
      if (!cardContent.value.meta) return false;

      if (type.value === "camp") return cardContent.value.meta.attributes;

      const availableAtts = tourRetreatAttributes.value.filter(
        (key) => cardContent.value.meta[key]
      );

      return availableAtts.map((key) => ({
        label: cardContent.value.meta[key],
        value: key,
      }));
    });

    // methods
    const cardLinkHover = (mouseenter = true) => {
      if (screen.touch) return;

      if (props.linkHoverActive) {
        cardLinkHovered.value = mouseenter;
        return;
      }
      console.log(
        "%c%s",
        "color:plum;",
        "card hovered but during transition, return"
      );
    };

    const stopPropPreventDefault = (event, val) => {
      if (val) {
        event.stopPropagation();
        event.preventDefault();
      }
    };

    const attributeTooltipOptions = (content) => {
      return type.value === "camp" ? { content, ...tooltipOptions } : false;
    };

    return {
      cardContent,
      isPost,
      isCamp,
      isTourRetreat,
      price,
      cardNavigation,
      cardContent,
      type,
      href,
      attributeTooltipOptions,
      stopPropPreventDefault,
      attributes,
      cardLinkHover,
    };
  },
};
</script>

<style lang="scss">
.card__content {
  @apply text-white py-2 px-3 flex flex-col flex-grow;
  @include text-shadow-banner;
  .heading {
    @apply mt-auto;
    margin-bottom: 0.08em;
  }
  .subheading {
    @apply pr-8;
    color: #eeeeee;
  }
  .meta {
    &__attributes {
      min-height: 1.8em;
      margin-bottom: 0.2em;
      .meta-price {
        @apply text-white;
        margin-bottom: 0;
        line-height: 1.2em;
        .price-from {
          font-size: 0.96em;
          line-height: 1em;
        }
      }
      .meta-attribute {
        @apply mr-0;
        span {
          color: #dddddd;
          line-height: 1.3rem;
        }
        &:before {
          color: #dfdfdf;
        }
      }
      &.meta__attributes-camp {
        .attributes {
          .meta-attribute {
            cursor: default;
            &:hover {
              &:before {
                color: white;
              }
            }
          }
        }
      }
    }
  }
  &.card__content-blog {
    .card-bottom {
      margin-bottom: 0.5rem;
    }
    .subheading {
      @apply pr-0;
      margin-left: 0.75em;
      span {
        @include text-ellipsis;
      }
    }
  }
}

@screen sm {
  .card__front {
    .heading {
      margin-bottom: 0.05em;
    }
    &--camp,
    &--tour,
    &--retreat {
      .subheading {
        padding-bottom: 0.3em;
      }
    }
    &--blog {
      .heading {
        margin-bottom: 0.1em;
      }
    }
  }
}

@screen lg {
  .card__content {
    @apply px-4;
    .meta {
      &__attributes {
        .meta-attribute {
          @apply pr-1;
        }
      }
    }
  }
  .card__front {
    &--camp {
      .meta__attributes-camp {
        .meta-attribute {
          font-size: 0.9em;
          &:before {
          }
        }
      }
    }
  }
}
@screen lg {
  .card__front {
    &--camp {
      .meta-price {
        padding-bottom: 0.05em;
      }
      .meta__attributes-camp {
        margin-bottom: 0.25em;
        margin-top: 0.15em;
        .meta-attribute {
          font-size: 0.95em;
          &:before {
          }
        }
      }
    }
  }
}
</style>
