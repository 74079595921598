const baseUrl =
  "https://cms.londolozi.com/wp-json/londolozi-cms/v1/getCurrencyExchangeRate";

export const fetchZarToTargetCurrencyRate = async (currencyCode) => {
  const response = await fetch(`${baseUrl}?currency=${currencyCode}`);

  const responseBody = await response.json();

  if (responseBody && responseBody.success) {
    return responseBody.rates[currencyCode];
  }

  throw new Error(
    `could not retrieve exchange rate for currency: ${currencyCode}`,
  );
};

export const currencyCodesMap = {
  AUD: "$",
  EUR: "€",
  GBP: "£",
  INR: "₹",
  USD: "$",
  ZAR: "R",
};

export const currencies = {
  AUD: "Australian Dollar",
  EUR: "Euro",
  GBP: "British Pound Sterling",
  USD: "United States Dollar",
  ZAR: "South African Rand",
  INR: "Indian Rupee",
};
