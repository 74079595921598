export default {
  state: {
    showBooking: false,
    submittingBooking: false,
    drawerOpen: false,
    drawerTransitioning: false,
    drawerTransitionDuration: 450,
    navigatingToViewType: null,
    landing: true,
    pageDataReady: false,
    galleryOpen: false,
  },

  mutations: {
    toggleDrawerState: (state) => {
      if (state.drawerTransitioning || state.submittingBooking) {
        return;
      }
      state.drawerTransitioning = true;
      state.drawerOpen = !state.drawerOpen;
      if (!state.drawerOpen) {
        state.showBooking = false;
      }
      setTimeout(() => {
        state.drawerTransitioning = false;
      }, state.drawerTransitionDuration);
    },
    openDrawerBooking: (state) => {
      state.showBooking = true;
      state.drawerOpen = true;
    },
    setNavigatingToViewType: (state, payload) => {
      console.log(
        "%c%s",
        "color:LightCoral;",
        "set navigatingToViewType to",
        payload
      );
      state.navigatingToViewType = payload;
    },
    toggleModifier: (state, payload) => {
      state[payload.key] = payload.val;
    },
    setLanding: (state, payload) => {
      state.landing = payload;
    },
  },
};
