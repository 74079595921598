import revive_payload_client_4sVQNw7RlN from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import globalComponents_8Lw6jBqn2X from "/build/plugins/globalComponents.js";
import pageViewTracking_client_qqYIKwqEMU from "/build/plugins/pageViewTracking.client.js";
import constants_vNRMFarEwm from "/build/plugins/constants.js";
import deviceDetector_client_TDYdek6R10 from "/build/plugins/deviceDetector.client.js";
import floatingVue_Dw7zeLLd4L from "/build/plugins/floatingVue.js";
import rellax_ydfQr8MezF from "/build/plugins/rellax.js";
import sharpspring_i2O9u1H1f9 from "/build/plugins/sharpspring.js";
import store_pBBVV6Lb3L from "/build/plugins/store.js";
import velocity_client_a9MMBZHYZ9 from "/build/plugins/velocity.client.js";
import vimeo_6OacVTktcd from "/build/plugins/vimeo.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  globalComponents_8Lw6jBqn2X,
  pageViewTracking_client_qqYIKwqEMU,
  constants_vNRMFarEwm,
  deviceDetector_client_TDYdek6R10,
  floatingVue_Dw7zeLLd4L,
  rellax_ydfQr8MezF,
  sharpspring_i2O9u1H1f9,
  store_pBBVV6Lb3L,
  velocity_client_a9MMBZHYZ9,
  vimeo_6OacVTktcd
]