export default {
  state: {
    cardTypes: {
      page: "page",
      camp: "camp",
      tour: "tour",
      retreat: "retreat",
    },
    list: null,
  },

  mutations: {
    setCards: (state, payload) => {
      state.list = payload;
      return state;
    },
  },

  getters: {
    cardsByType: (state) => (type) =>
      state.list.filter((card) => card.type === type),

    childCardsForIndexId: (state, getters) => (id) => {
      const indexCard = state.list.find(
        (card) => card.id === id && card.children !== null,
      );

      if (!indexCard) {
        return [];
      }
      return indexCard.children.map((childId) => getters.cardById(childId));
    },

    childCardsIdsForIndexId: (state) => (id) => {
      const indexCard = state.list.find(
        (card) => card.id === id && card.children !== null,
      );

      if (!indexCard?.children) {
        return [];
      }

      return indexCard.children.map((childId) => childId);
    },

    cardById: (state) => (id) => state.list?.find((card) => card.id == id),

    cardsByIds: (state) => (ids) =>
      state.list.filter((card) => ids.indexOf(card.id) !== -1),
  },
};
