import cloneDeep from "lodash.clonedeep";
import enquiryActions from "@/store/modules/enquiry-mutation-types";

export const defaultBookingFilters = {
  loading: false,
  stepIndex: 0,
  doneIndex: 4,
  firstName: null,
  lastName: null,
  email: null,
  contactNumber: null,
  privacyAccepted: false,
  valid: false,
  filters: {
    arrival: null,
    departure: null,
    adults: 2,
    children: 0,
    children1216: 0,
    beenBefore: false,
    valid: false,
  },
  extra: {
    referred: null,
    travelAgent: null,
    message: null,
    daily: null,
    monthly: null,
    valid: false,
    bookedFlights: false,
    budget: null,
  },
};

export const defaultGeneralFilters = {
  loading: false,
  firstName: null,
  lastName: null,
  email: null,
  contactNumber: null,
  message: null,
  privacyAccepted: false,
};

export const defaultNewsletterFilters = {
  loading: false,
  firstName: null,
  lastName: null,
  email: null,
  daily: null,
  monthly: null,
};

export const defaultAgentFilters = {
  loading: false,
  firstName: null,
  lastName: null,
  companyName: null,
  email: null,
  contactNumber: null,
  privacyAccepted: false,
  message: null,
  valid: null,
};

export const defaultRetreatFilters = {
  loading: false,
  firstName: null,
  lastName: null,
  email: null,
  message: null,
  privacyAccepted: false,
};

export const defaultFilters = {
  general: defaultGeneralFilters,
  newsletter: defaultNewsletterFilters,
  agent: defaultAgentFilters,
  retreat: defaultRetreatFilters,
  booking: defaultBookingFilters,
};

export default {
  state: {
    general: cloneDeep(defaultGeneralFilters),
    newsletter: cloneDeep(defaultNewsletterFilters),
    agent: cloneDeep(defaultAgentFilters),
    retreat: cloneDeep(defaultRetreatFilters),
    booking: cloneDeep(defaultBookingFilters),
  },

  mutations: {
    [enquiryActions.reset]: (state, { scope, filters }) => {
      state[scope] = filters;
      return state;
    },

    // general enquiries

    [enquiryActions.general.contact.firstName]: (state, firstName) => {
      state.general.firstName = firstName;
      return state;
    },

    [enquiryActions.general.contact.lastName]: (state, lastName) => {
      state.general.lastName = lastName;
      return state;
    },

    [enquiryActions.general.contact.email]: (state, email) => {
      state.general.email = email;
      return state;
    },

    [enquiryActions.general.contact.contactNumber]: (state, contactNumber) => {
      state.general.contactNumber = contactNumber;
      return state;
    },

    [enquiryActions.general.contact.message]: (state, message) => {
      state.general.message = message;
      return state;
    },

    [enquiryActions.general.contact.privacyAccepted]: (state, privacy) => {
      state.general.privacyAccepted = privacy;
      return state;
    },

    // agent enquiries

    [enquiryActions.agent.contact.firstName]: (state, firstName) => {
      state.agent.firstName = firstName;
      return state;
    },

    [enquiryActions.agent.contact.lastName]: (state, lastName) => {
      state.agent.lastName = lastName;
      return state;
    },

    [enquiryActions.agent.contact.email]: (state, email) => {
      state.agent.email = email;
      return state;
    },

    [enquiryActions.agent.contact.contactNumber]: (state, contactNumber) => {
      state.agent.contactNumber = contactNumber;
      return state;
    },

    [enquiryActions.agent.contact.privacyAccepted]: (state, privacy) => {
      state.agent.privacyAccepted = privacy;
      return state;
    },

    [enquiryActions.agent.contact.valid]: (state, valid) => {
      state.agent.valid = valid;
      return state;
    },

    [enquiryActions.agent.contact.message]: (state, message) => {
      state.agent.message = message;
      return state;
    },

    [enquiryActions.agent.contact.companyName]: (state, companyName) => {
      state.agent.companyName = companyName;
      return state;
    },

    // newsletter enquiries

    [enquiryActions.newsletter.contact.firstName]: (state, firstName) => {
      state.newsletter.firstName = firstName;
      return state;
    },

    [enquiryActions.newsletter.contact.lastName]: (state, lastName) => {
      state.newsletter.lastName = lastName;
      return state;
    },

    [enquiryActions.newsletter.contact.email]: (state, email) => {
      state.newsletter.email = email;
      return state;
    },

    [enquiryActions.newsletter.contact.daily]: (state, daily) => {
      state.newsletter.daily = daily;
      return state;
    },

    [enquiryActions.newsletter.contact.monthly]: (state, monthly) => {
      state.newsletter.monthly = monthly;
      return state;
    },

    // retreat enquiries

    [enquiryActions.retreat.contact.firstName]: (state, firstName) => {
      state.retreat.firstName = firstName;
      return state;
    },

    [enquiryActions.retreat.contact.lastName]: (state, lastName) => {
      state.retreat.lastName = lastName;
      return state;
    },

    [enquiryActions.retreat.contact.email]: (state, email) => {
      state.retreat.email = email;
      return state;
    },

    [enquiryActions.retreat.contact.privacyAccepted]: (state, privacy) => {
      state.retreat.privacyAccepted = privacy;
      return state;
    },

    [enquiryActions.retreat.contact.message]: (state, message) => {
      state.retreat.message = message;
      return state;
    },

    // booking enquiries

    [enquiryActions.booking.stepIndex]: (state, index) => {
      state.booking.stepIndex = index;
      return state;
    },

    [enquiryActions.booking.contact.firstName]: (state, firstName) => {
      state.booking.firstName = firstName;
      return state;
    },

    [enquiryActions.booking.contact.lastName]: (state, lastName) => {
      state.booking.lastName = lastName;
      return state;
    },

    [enquiryActions.booking.contact.email]: (state, email) => {
      state.booking.email = email;
      return state;
    },

    [enquiryActions.booking.contact.contactNumber]: (state, contactNumber) => {
      state.booking.contactNumber = contactNumber;
      return state;
    },

    [enquiryActions.booking.contact.valid]: (state, valid) => {
      state.booking.valid = valid;
      return state;
    },

    [enquiryActions.booking.contact.privacyAccepted]: (state, privacy) => {
      state.booking.privacyAccepted = privacy;
      return state;
    },

    [enquiryActions.booking.filters.arrival]: (state, arrival) => {
      state.booking.filters.arrival = arrival;
      return state;
    },

    [enquiryActions.booking.filters.departure]: (state, departure) => {
      state.booking.filters.departure = departure;
      return state;
    },

    [enquiryActions.booking.filters.adults]: (state, adults) => {
      state.booking.filters.adults = adults;
      return state;
    },

    [enquiryActions.booking.filters.children]: (state, children) => {
      state.booking.filters.children = children;
      return state;
    },

    [enquiryActions.booking.filters.children1216]: (state, children1216) => {
      state.booking.filters.children1216 = children1216;
      return state;
    },

    [enquiryActions.booking.filters.beenBefore]: (state, beenBefore) => {
      state.booking.filters.beenBefore = beenBefore;
      return state;
    },

    [enquiryActions.booking.filters.valid]: (state, valid) => {
      state.booking.filters.valid = valid;
      return state;
    },

    [enquiryActions.booking.extra.referred]: (state, referred) => {
      state.booking.extra.referred = referred;
      return state;
    },

    [enquiryActions.booking.extra.travelAgent]: (state, travelAgent) => {
      state.booking.extra.travelAgent = travelAgent;
      return state;
    },

    [enquiryActions.booking.extra.message]: (state, message) => {
      state.booking.extra.message = message;
      return state;
    },

    [enquiryActions.booking.extra.budget]: (state, budget) => {
      state.booking.extra.budget = budget;
      return state;
    },

    [enquiryActions.booking.extra.bookedFlights]: (state, bookedFlights) => {
      state.booking.extra.bookedFlights = bookedFlights;
      return state;
    },

    [enquiryActions.booking.extra.daily]: (state, daily) => {
      state.booking.extra.daily = daily;
      return state;
    },

    [enquiryActions.booking.extra.monthly]: (state, monthly) => {
      state.booking.extra.monthly = monthly;
      return state;
    },
  },
};
