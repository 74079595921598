<template>
  <div
    class="schedule width-sm"
    :class="[{ 'left-line': hasLeftLine }, { 'right-aligned': !leftAligned }]"
  >
    <div class="schedule__heading pb-1">
      <h5 class="flex-wrap">
        <span class="time separator-left trajan"
          ><span>{{ time }}</span></span
        ><span
          class="title w-full min-w-full text-black leading-normal text-xl"
          v-if="title"
          >{{ title }}</span
        >
      </h5>
    </div>
    <div
      v-if="hasContent"
      class="schedule__content my-child-0 pt-2 pb-1 no-slider-arrows"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LondoSchedule",
  props: {
    title: {
      type: String,
    },
    time: {
      type: String,
      required: true,
    },
    hasContent: {
      type: Boolean,
      default: false,
    },
    hasLeftLine: {
      type: Boolean,
      default: true,
    },
    leftAligned: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.schedule {
  @apply mt-8;
  &.left-line {
    &:after {
      top: 0;
      height: 100%;
    }
  }
  & + .schedule {
    @apply mt-0 pt-6;
  }
  & + *:not(.schedule) {
    @apply mt-8;
  }
  &__heading {
    h5 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
      padding-bottom: 0px !important;
      &:before {
        display: none !important;
      }
      .time {
        line-height: 0.95;
        &:after {
          margin-top: 0;
        }
      }
      .title {
        @apply font-bold;
      }
    }
  }
  &__content {
  }
  &.right-aligned {
    .schedule__heading {
      h5 {
        .time {
          span {
            @apply order-2;
          }
          &:after {
            @apply order-1 mr-4 ml-0;
          }
        }
      }
    }
  }
}
.main-content {
  .schedule {
    &__content {
      ul:not([class]),
      ol:not([class]) {
        @apply mb-6;
        > li {
          @apply mb-2;
        }
      }
    }
  }
}

.collapsible__content__content {
  > .schedule {
    &:first-child {
      padding-top: 0.7rem !important;
    }
    padding-left: 0;
    &:after {
      display: none;
    }
  }
}
</style>
