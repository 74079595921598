import { defineNuxtPlugin } from "#app";
import LondoCard from "@/components/Cards/Card.vue";
import LondoSwipeCarousel from "@/components/SwipeCarousel.vue";
import LondoBlogCarousel from "@/components/Cards/CardsCarouselBlog.vue";
import LondoCollapsible from "@/components/Collapsible.vue";
import LondoSchedule from "@/components/Schedule.vue";
import GlobalLink from "@/components/GlobalLink.vue";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("londo-card", LondoCard);
  nuxtApp.vueApp.component("londo-swipe-carousel", LondoSwipeCarousel);
  nuxtApp.vueApp.component("londo-blog-carousel", LondoBlogCarousel);
  nuxtApp.vueApp.component("londo-collapsible", LondoCollapsible);
  nuxtApp.vueApp.component("londo-schedule", LondoSchedule);
  nuxtApp.vueApp.component("global-link", GlobalLink);
});
