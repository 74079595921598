export default {
  state: {
    loading: false,
    bookingProgressStates: {
      inprogress: "inprogress",
      done: "done",
    },
    bookingSteps: {
      availability: 1,
      selectCamp: 2,
      quote: 3,
      details: 4,
      thankyou: 5,
    },
    bookingIdToCampId: {
      RS8: 295,
      RS9: 298,
      RS10: 297,
      RS11: 299,
      RS12: 296,
    },
    filters: {
      arrival: null,
      departure: null,
      adults: 2,
      children: 0,
      children1216: 0,
      rooms: 1,
      itinerary: true,
    },
    contact: {
      firstName: null,
      lastName: null,
      email: null,
      contactNumber: null,
    },
    bookingServerErr: null,
    bookingState: null,
    notes: null,
    outline: null,
    quote: null,
    searchResults: null,
    selectedBooking: null,
    step: 1,
  },

  mutations: {
    setArrival: (state, arrival) => {
      state.filters.arrival = arrival;
      return state;
    },

    setDeparture: (state, departure) => {
      state.filters.departure = departure;
      return state;
    },

    setAdults: (state, adults) => {
      state.filters.adults = adults;
      return state;
    },

    setChildren: (state, children) => {
      state.filters.children = children;
      return state;
    },

    setChildren1216: (state, children) => {
      state.filters.children1216 = children;
      return state;
    },

    setRooms: (state, rooms) => {
      state.filters.rooms = rooms;
      return state;
    },

    setItinerary: (state, itinerary) => {
      state.filters.itinerary = itinerary;
      return state;
    },

    setFirstName: (state, firstName) => {
      state.contact.firstName = firstName;
      return state;
    },

    setLastName: (state, lastName) => {
      state.contact.lastName = lastName;
      return state;
    },

    setEmail: (state, email) => {
      state.contact.email = email;
      return state;
    },

    setContactNumber: (state, contactNumber) => {
      state.contact.contactNumber = contactNumber;
      return state;
    },

    setBookingNotes: (state, notes) => {
      state.notes = notes;
      return state;
    },

    setFilters: (state, payload) => {
      state.filters = payload;
      return state;
    },

    setBookingStep: (state, payload) => {
      state.step = payload;
      return state;
    },

    setBookingLoading: (state, payload) => {
      state.loading = payload;
      return state;
    },

    setBookingOutline: (state, payload) => {
      state.outline = payload;
      return state;
    },

    setBookingSearchResults: (state, payload) => {
      state.searchResults = payload;
      return state;
    },

    setSelectedBooking: (state, payload) => {
      state.selectedBooking = payload;
      return state;
    },

    setBookingState: (state, payload) => {
      state.bookingState = payload;
      return state;
    },

    setBookingServerError: (state, payload) => {
      state.bookingServerErr = payload;
      return state;
    },

    setQuote: (state, payload) => {
      state.quote = payload;
      return state;
    },
  },

  actions: {
    resetBookingWizard: ({ commit, state }) => {
      commit("setBookingStep", state.bookingSteps.availability);
      commit("setBookingSearchResults", null);
      commit("setQuote", null);
      commit("setSelectedBooking", null);
      commit("setBookingState", null);
    },
  },
};
