<!-- eslint-disable max-len -->
<template>
  <div
    :class="`card__content__back--${type}`"
    class="card__content card__back leading-relaxed"
  >
    <div class="card__back__header flex flex-col">
      <div v-if="price" class="meta-price">
        <span v-if="price" class="price-from">From </span
        ><span v-if="price" class="price-price">R{{ price }}</span>
        <span class="text-grey-700" v-if="date">{{
          cardContent.dateFormatted
        }}</span>
      </div>
      <div v-if="isPost" class="flex post-meta">
        <div class="card-avatar avatar flex-shrink-0">
          <img :src="cardContent.authorImage" alt="author image" />
        </div>
        <div class="flex flex-col overflow-hidden">
          <span class="text-grey-700 italic">{{ cardContent.author }}</span>
          <span class="text-grey-700" v-if="date">{{
            cardContent.dateFormatted
          }}</span>
        </div>
      </div>
      <div class="heading mt-auto" v-html="cardContent.title"></div>
    </div>

    <div v-if="cardContent.subtitle" class="card__back__subheading">
      <div class="lead-card drop-cap w-full">{{ cardContent.subtitle }}</div>
    </div>

    <div
      v-if="isTourRetreat && cardContent.meta.tags"
      class="meta__attributes__tags flex"
    >
      <span
        v-for="(tag, index) in cardContent.meta.tags.slice(0, 5)"
        :key="index"
        class="meta-tag"
        >{{ tag }}</span
      >
    </div>
    <div
      class="card__back__content"
      :class="{ 'scrollbar-thin scrollbar-thin--vertical': !screen.touch }"
    >
      <div v-if="isPost" class="lead-card drop-cap" v-html="postIntro"></div>
      <ul v-if="isCamp" class="ul-camp-attributes leading-loose w-full">
        <li v-for="(attribute, key) in attributes.camp" :key="key" class="flex">
          <span class="attribute-label mr-auto">{{ attribute }}</span>
          <i
            v-if="
              cardContent.meta.attributes.find(
                (element) => element.value === key
              )
            "
            class="meta-attribute icon"
            :class="`icon-${key}`"
          ></i>
          <div v-else class="no-attribute">x</div>
        </li>
        <li v-if="cardContent.meta.rooms" class="flex">
          <span class="attribute-label mr-auto">Room size</span>
          <span class="font-dinwide font-bold text-black room_size"
            >{{ cardContent.meta.room_size || 10 }}
          </span>
          <span class="font-dinwide">m²</span>
        </li>
        <li v-if="cardContent.meta.rooms" class="flex">
          <span class="attribute-label mr-auto pr-2">Rooms</span>
          <span
            class="font-dinwide font-bold text-black uppercase text-right leading-none camp-card-rooms"
            >{{ roomsTotal }} {{ roomsType }}</span
          >
        </li>
      </ul>

      <ul v-if="isTourRetreat" class="w-full ul-tour-retreat-attributes">
        <li v-for="key in tourRetreatsAttributes" :key="key" class="flex">
          <i
            v-if="cardContent.meta[key]"
            class="meta-attribute icon"
            :class="`icon-${key}`"
          ></i>
          <span class="flex">
            {{ cardContent.meta[key] }}
          </span>
        </li>
      </ul>
      <div
        v-if="cardContent.description"
        v-html="cardContent.description"
        class="my-child-0"
      ></div>
    </div>
    <div class="card__back__footer mt-auto">
      <a
        :href="href"
        :target="isPost ? '_blank' : '_self'"
        v-bind:rel="isPost ? 'noreferrer' : false"
        class="btn btn-nav btn-next mt-0 mb-0 ml-auto"
        @click="cardNavigation"
        draggable="false"
        ondragstart="return false;"
      >
        <span>{{ type }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useCardFace } from "@/composables/useCardFace";

export default {
  props: {
    cardContent: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "page",
    },
    href: {
      type: String,
      default: "#",
    },
  },
  setup(props, context) {
    const attributes = ref({
      camp: {
        relais: "Relais & Châteaux",
        pool: "Private Plunge Pool",
        children: "Children Welcome",
        connected: "Connected Rooms",
      },
      tourRetreats: ["days", "when", "stops"],
    });

    const {
      isPost,
      isCamp,
      isTourRetreat,
      cardContent,
      type,
      price,
      href,
      cardNavigation,
    } = useCardFace(props, context);

    const date = computed(() =>
      isPost.value ? cardContent.value.dateFormatted : false
    );

    const postIntro = computed(() => {
      return isPost.value ? `${cardContent.value.excerpt}...` : false;
    });

    const roomsType = computed(() => cardContent.value.meta.rooms[0].type);

    const roomsTotal = computed(() => {
      const total = cardContent.value.meta.rooms.reduce((a, b) => ({
        number: parseInt(a.number, 10) + parseInt(b.number, 10),
      }));
      return total.number;
    });

    const tourRetreatsAttributes = computed(() => {
      return attributes.value.tourRetreats.filter(
        (attr) => cardContent.value.meta[attr]
      );
    });

    return {
      attributes,
      isCamp,
      isTourRetreat,
      type,
      price,
      href,
      cardNavigation,
      postIntro,
      date,
      roomsType,
      roomsTotal,
      tourRetreatsAttributes,
      isPost,
    };
  },
};
</script>

<style lang="scss">
.card__content {
  &.card__back {
    @apply overflow-hidden h-full flex flex-col flex-shrink-0 text-black;
    font-size: 0.8em;
    padding-bottom: 0;
    border-radius: calc(0.7 * var(--card-border-radius));
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.6) 40%,
      rgba(255, 255, 255, 0.8) 87%
    );
    @include text-shadow-card-white;
    .card__back__header {
      @apply flex flex-shrink-0;
      margin: 2em 0 0.2em;
      .post-meta {
        font-size: 1em;
        line-height: 1.4;
        margin: -0.2em 0 1em 0;
        span {
          @include text-ellipsis;
        }
        .card-avatar {
          font-size: 1.2em;
          margin-right: 0.55em;
          margin-top: -0.08em;
          box-shadow: none;
          z-index: 10;
        }
      }
      .meta-price {
        line-height: 1.625;
        font-size: 1em;
        margin-top: -0.6em;
        .price-price {
          margin-left: 0.1em;
        }
      }
    }
    .meta__attributes__tags {
      @apply flex-wrap;
      margin: 0.4em 0 0;
      padding-bottom: 0em;
      .meta-tag {
        @apply text-grey-700;
        font-size: 1em;
        background: rgba(255, 255, 255, 0.5);
        padding-left: 0.8em;
        padding-right: 0.8em;
        border-radius: 0.125em;
        margin-right: 0.4em;
        margin-bottom: 0.4em;
        line-height: 1.75em;

        &:last-child {
          margin-right: 0;
        }
      }
      + .card__back__content {
        margin-top: 0.1em;
      }
    }
    .card__back__subheading {
      @apply flex flex-shrink-0;
      margin-bottom: 0.2em;
    }
    .card__back__content {
      @apply flex flex-col flex-shrink overflow-y-auto;
      padding-right: 0.6em;
      margin-top: 0.5em;
      .ul-camp-attributes {
        max-height: 10.3em;
        li {
          @apply relative;
          @include bullet;
          padding-left: 1.5em;
          &:before {
            color: $grey-700;
            font-size: 0.8em;
            top: 0.8em;
          }
          .room_size {
            padding-right: 0.15em;
          }
          .camp-card-rooms {
            padding-top: 0.5em;
          }
          .meta-attribute {
            @apply mr-0 flex justify-center;
            width: 1.5em;
            &:before {
              @apply mr-0;
              color: #6c6c6c;
            }
            &.icon-relais {
              &:before {
                font-size: 1.1em;
              }
            }
          }
          .no-attribute {
            @apply mr-0 flex justify-center font-sans;
            font-size: 1.2em;
            width: 1.5em;
            color: $grey-400;
            line-height: 1.5;
          }
        }
      }
      .ul-tour-retreat-attributes {
        li {
          line-height: 1.6;
          padding-bottom: 0.4em;
          &:last-child {
            padding-bottom: 0;
          }
          .meta-attribute {
            @apply flex justify-center items-center;
            width: 1.4em;
            margin-right: 0.6em;
            height: 1.2em;
            &:before {
              @apply mr-0;
              color: #6c6c6c;
            }
          }
        }
      }
    }
    .card__back__footer {
      @apply flex flex-shrink-0;
      .btn-nav {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: 1.25em;
        line-height: 1.2em;
        &::before {
          padding-left: 1em;
          padding-right: 1em;
        }
        &::after {
          line-height: 1.5em;
          font-size: 0.8em;
          content: "View";
          color: $grey-500;
        }
      }
    }
    .lead-card {
      @include drop-cap;
      @apply italic;
      color: #444444;
      line-height: 1.6;
      min-height: 3.2em;
      &::first-letter {
        font-size: 3.3em;
      }
    }
    &.card__content__back--post {
      .card__back__content {
        margin-top: 0.2em;
      }
    }
  }
}
.app--no-touch {
  .card__content {
    &.card__back {
      .card__back__footer {
        .btn-nav {
          &:hover {
            margin-right: 0;
            padding-left: 0.6em;
            &:before {
              padding-right: 0.6em;
            }
          }
        }
      }
    }
  }
}
@screen sm {
  .card__content {
    &.card__back {
      .card__back__header {
        @apply flex flex-shrink-0;
        border-radius: var(--card-border-radius);
        margin-top: 2.3em;
      }
      .card__back__content {
        .ul-tour-retreat-attributes {
          li {
            .meta-attribute {
              height: 1.4em;
            }
          }
        }
      }
    }
    &.card__content__back {
      &--page {
        .card__back__header {
          margin-top: 2.1em;
        }
      }
    }
  }
}
@screen md {
  .card__content {
    &.card__back {
      .card__back__header {
        @apply flex flex-shrink-0;
        margin-top: 2em;
        .post-meta {
          margin-top: -0.8em;
        }
      }
      .meta__attributes__tags {
        margin: 0.5em 0 0;
      }
    }
    &.card__content__back {
      &--page {
        .card__back__header {
          margin-top: 2.7em;
        }
      }
    }
  }
}
@screen lg {
  .card__content {
    &.card__back {
      font-size: 0.785em;
      .card__back__header {
        @apply flex flex-shrink-0;
        margin-top: 1.6em;
      }
      .card__back__subheading {
        margin-bottom: 0.1em;
      }
      .card__back__content {
        margin-top: 0.5em;
        .ul-tour-retreat-attributes {
          li {
            .meta-attribute {
              height: 1.6em;
            }
          }
        }
      }
      &.card__content__back--post {
        .card__back__content {
          margin-top: 0.5em;
        }
      }
      .meta__attributes__tags {
        margin: 0.6em 0 0;
      }
    }
    &.card__content__back {
      &--page {
        .card__back__header {
          margin-top: 2.9em;
        }
      }
    }
  }
}
@screen xl {
  .card__content {
    &.card__back {
      font-size: 0.795em;
    }
  }
}
</style>
