<!-- eslint-disable max-len -->
<template>
  <div
    v-if="cards && cards.length === 1"
    class="relative w-full h-full z-30 mb-10 mx-auto flex justify-center swiper-single-card fade-in animation-delay-200 cards"
    :class="{ 'cards--no-shine': disableShine }"
  >
    <londo-card
      :linkHoverActive="!disableActiveNavigation"
      :preventNavigation="disableActiveNavigation"
      :card="cards[0]"
    />
  </div>
  <div
    v-else
    :class="[
      gridLayout ? 'swiper--grid' : 'swiper--carousel',
      { 'cards--no-shine': disableShine },
    ]"
    class="swiper relative w-full my-10 fade-in animation-delay-200 cards"
  >
    <div
      v-if="heading || layoutSwitcher"
      class="swiper__header width-md-sm w-full relative pt-2 mb-6"
    >
      <h3 class="mb-0 mt-0">
        <span v-if="headingText">{{ headingText }}</span>
      </h3>
      <div
        class="text-right w-full carousel-heading-sub italic text-grey-300 leading-normal text-sm xl:text-base"
      >
        Select a card
      </div>
      <div
        v-if="layoutSwitcher || isHome"
        class="layout-actions absolute left-0 top-0 flex"
      >
        <button
          v-tooltip="showTooltip('Carousel layout')"
          class="btn btn-layout btn-layout-carousel icon icon-cards-carousel2"
          :class="{ 'btn-layout--active': !gridLayout }"
          @click="toggleGrid(false)"
        ></button>
        <button
          v-tooltip="showTooltip('Grid layout')"
          class="btn btn-layout btn-layout-grid icon icon-grid"
          :class="{ 'btn-layout--active': gridLayout }"
          @click="toggleGrid(true)"
        ></button>
      </div>
    </div>
    <div
      class="swiper__wrap width-lg flex-col flex relative items-center w-full"
    >
      <div
        :style="cssProps"
        class="swiper-container relative width-md-sm h-full z-30 mb-5 w-full"
        ref="cardCarousel"
        :class="{
          'max-cols-3': gridLayout && (cards.length < 7 || width < xl),
        }"
      >
        <div
          v-for="(item, index) in cards"
          :key="index"
          class="absolute top-0 swiper-slide"
          :class="[slideClass(index), { 'slide-grid': gridLayout }]"
          :style="slideStyle(index)"
          :data-index="index"
          @click="handleClick(index)"
        >
          <londo-card
            :ref="`item-${index}`"
            :index="index + 1"
            :count="cards.length"
            :card="item"
            :hideNumber="hideCardNumber"
            :preventEvents="index !== activeIndex && !gridLayout"
            :preventNavigation="disableActiveNavigation"
            :initialFlipped="flipped"
            :linkHoverActive="linkHoverActive && !disableActiveNavigation"
            class="card--swiper"
          />
        </div>
      </div>
      <button
        v-if="grid.lg"
        v-show="!gridLayout"
        class="btn-slide btn-back fade-up"
        @click="prev"
      ></button>
      <button
        v-if="grid.lg"
        v-show="!gridLayout"
        class="btn-slide btn-next fade-up"
        @click="next"
      ></button>
    </div>
    <ul class="indicator-track fade-up" v-show="!gridLayout && !hideIndicators">
      <li
        v-for="index in cards.length"
        class="indicator"
        :key="index"
        :class="{ 'indicator--active': index - 1 === activeIndex }"
      ></li>
    </ul>
    <div
      v-if="!grid.lg"
      v-show="!gridLayout"
      class="swiper__helper absolute left-0 flex h-6 text-grey-200 items-center leading-normal fade-up"
    >
      <div class="swipe-icon h-full relative">
        <i class="icon icon-arrow-left text-xs absolute"></i>
        <i class="icon icon-hand text-xl"></i>
      </div>
      <span class="italic text-sm inline-block text-grey-300"
        >Swipe <span v-if="device.ios || device.ipad">+ tap </span>
        <span v-if="grid.sm">to browse</span></span
      >
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable operator-linebreak */
import debounce from "lodash.debounce";
import { ref, onMounted, computed, watch, nextTick } from "vue";
import store from "@/store";
import { useDevice } from "@/composables/useDevice";

export default {
  props: {
    cards: {
      type: Array,
      required: true,
    },
    routeScope: {
      type: String,
    },
    visible: {
      type: Number,
      default: 5,
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
    transition: {
      type: Number,
      default: 500,
    },
    maxWidth: {
      type: [Number, Boolean],
      default: false,
    },
    hideIndicators: {
      type: Boolean,
      default: false,
    },
    disableActiveNavigation: {
      type: Boolean,
      default: false,
    },
    layoutSwitcher: {
      type: Boolean,
      default: true,
    },
    heading: {
      type: [String, Boolean],
      default: false,
    },
    flipped: {
      type: Boolean,
      default: false,
    },
    gridInitial: {
      type: Boolean,
      default: false,
    },
    hideCardNumber: {
      type: Boolean,
      default: false,
    },
  },
  async setup(props, { emit }) {
    let Hammer;

    const route = useRoute();
    const activeIndex = ref(0);
    const itemWidth = ref(100);
    const itemHeight = ref(100);
    const swiperWidth = ref(100);
    const cardCarousel = ref(null);
    const gridLayout = ref(props.gridInitial);
    const hammer = ref(null);
    let hammerSwipeOptions;
    const linkHoverActive = ref(false);
    const { device } = useDevice();

    const tooltipOptions = {
      container: ".primary",
      theme: "transparent-tooltip",
      triggers: screen.touch ? [] : ["hover"],
    };

    const showTooltip = (content) => {
      return { content, ...tooltipOptions };
    };

    onMounted(() => {
      if (process.client) {
        import("hammerjs").then((module) => {
          Hammer = module.default;
          hammerSwipeOptions = {
            direction: Hammer.DIRECTION_HORIZONTAL,
            threshold: 1,
            velocity: 0.1,
          };

          if (props.cards.length > 1) {
            if (!screen.touch) linkHoverActive.value = true;
            if (screen.touch) gridLayout.value = true;

            if (Hammer) {
              initSwiping();
            }

            // timeout resolves cms shortcode driven carousel init issue
            setTimeout(() => {
              setActiveElementDimensions();
            }, 0);
            window.addEventListener("resize", handleResize);

            if (gridLayout.value) {
              nextTick(pauseSwiping);
            }
          }
        });
      }
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      destroySwiping();
    });

    const iosSlideZindexTransition =
      device?.ios || device?.ipad ? `, z-index ${props.transition / 4}ms` : "";

    // START COMPUTED
    const maxVisible = computed(() => {
      if (props.cards.length < 5 || props.visible === 3) {
        return 3;
      }
      return 5;
    });

    const cssProps = computed(() => ({
      "--slide-base-width": `${itemWidth.value}px`,
      "--slide-base-height": `${itemHeight.value}px`,
      "--swiper-max-width": `${props.maxWidth}px`,
      "--slide-transition-duration": `${props.transition}ms`,
      "--slide-transition": `all ${props.transition}ms ease 0s ${iosSlideZindexTransition}`,
      "--slide-transition-active": `all ${
        props.transition
      }ms ease 0s, z-index ${props.transition / 4}ms`,
      "--slide-transition-hidden": `transform ${props.transition}ms ease ${
        props.transition / 2
      }ms, opacity ${props.transition}ms ease 0s, z-index 0s`,
    }));

    const headingText = computed(() => {
      return typeof props.heading === "string" ? props.heading : "Explore";
    });

    const isHome = computed(() => {
      return route.name === "home___en";
    });

    const disableShine = computed(() => {
      return device?.ios || device?.ipad || device?.iphone;
    });

    // START METHODS
    const windowResize = debounce(() => handleResize(), 150, {
      leading: false,
      trailing: true,
    });

    const checkParentNodeWidth = (node, cumulativePadding = 0) => {
      if (node === null) {
        return 200;
      }
      const computedStyle = getComputedStyle(node);
      const padding =
        parseFloat(computedStyle.paddingLeft) +
        parseFloat(computedStyle.paddingRight) +
        cumulativePadding;

      if (node.clientWidth !== 0) {
        const widthWithoutPadding = node.clientWidth - padding;
        return widthWithoutPadding;
      }
      return checkParentNodeWidth(node?.parentNode, padding);
    };

    const setContainerWidth = () => {
      const multiplier = maxVisible.value === 3 ? 0.9 : 0.76;
      const maxWidthForNumberOfSlides = Math.round(
        maxVisible.value * itemWidth.value * multiplier
      );

      let containerWidth = cardCarousel.value?.offsetWidth;
      if (containerWidth === 0) {
        // recursively check parent nodes for width
        containerWidth = checkParentNodeWidth(cardCarousel.value);
      }

      swiperWidth.value =
        containerWidth < maxWidthForNumberOfSlides
          ? containerWidth
          : maxWidthForNumberOfSlides;
    };

    const setActiveElementDimensions = () => {
      if (!process.client && typeof document !== "undefined") {
        return;
      }
      // get card dimension from element in dom
      // needed to get dimensions in case child card is hidden
      const cardEl = document.getElementById("card-sizer");

      if (!cardEl) return;

      itemHeight.value = cardEl.offsetHeight;
      itemWidth.value = cardEl.offsetWidth;

      setContainerWidth();
    };

    const handleResize = () => {
      setActiveElementDimensions();
    };

    const toggleGrid = (val) => {
      if (gridLayout.value === val) return;

      gridLayout.value = val;
      if (val) pauseSwiping();
      else initSwiping();
    };

    const initSwiping = () => {
      const consoleMessage = hammer.value
        ? "reinitialise swiping"
        : "initialise swiping";
      console.log("%c%s", "color:deepskyblue;", consoleMessage);

      if (!hammer.value) {
        hammer.value = new Hammer(cardCarousel.value);

        hammer.value.get("swipe").set(hammerSwipeOptions);
      }

      hammer.value.on(
        "swiperight",
        debounce(() => prev(), 100)
      );
      hammer.value.on(
        "swipeleft",
        debounce(() => next(), 100)
      );
    };

    const handleClick = (index) => {
      if (index === activeIndex.value || gridLayout.value) return;

      const indexDiff = activeIndex.value - index;

      if (indexDiff === 1 || indexDiff === -props.cards.length + 1) {
        prev();
        return;
      }

      if (indexDiff === -1 || indexDiff === props.cards.length - 1) {
        next();
        return;
      }

      const prevSlideOrReset = (prevSlideIndex) => {
        if (prevSlideIndex === -1) {
          return props.cards.length - 1;
        }

        return prevSlideIndex;
      };

      const nextSlideOrReset = (nextSlideIndex) => {
        if (nextSlideIndex === props.cards.length) {
          return 0;
        }

        return nextSlideIndex;
      };

      if ((indexDiff > 0 && indexDiff !== 2) || indexDiff === -2) {
        const newIndex = (activeIndex.value + 1) % props.cards.length;
        activeIndex.value = newIndex;
        setTimeout(() => {
          activeIndex.value = nextSlideOrReset(newIndex + 1);
        }, props.transition);
      } else {
        const newIndex = (activeIndex.value - 1) % props.cards.length;
        activeIndex.value = prevSlideOrReset(newIndex);
        setTimeout(() => {
          activeIndex.value = prevSlideOrReset(activeIndex.value - 1);
        }, props.transition);
      }
    };

    const pauseSwiping = () => {
      console.log("%c%s", "color:deepskyblue;", "pause swiping");
      hammer.value?.off("swiperight");
      hammer.value?.off("swipeleft");
    };

    const destroySwiping = () => {
      if (!hammer.value) {
        return;
      }
      console.log("%c%s", "color:deepskyblue;", "destroy swiping");
      hammer.value.destroy();
      hammer.value = null;
    };

    const changeActiveIndex = (newIndex) => {
      if (!device?.touch) linkHoverActive.value = false;
      activeIndex.value = newIndex;
      if (!device?.touch) {
        setTimeout(() => {
          linkHoverActive.value = true;
        }, props.transition);
      }
    };

    const prev = () => {
      if (activeIndex.value === 0) {
        changeActiveIndex(props.cards.length - 1);
        return;
      }
      changeActiveIndex(activeIndex.value - 1);
    };

    const next = () => {
      if (activeIndex.value === props.cards.length - 1) {
        changeActiveIndex(0);
        return;
      }
      changeActiveIndex(activeIndex.value + 1);
    };

    const slideClass = (index) => {
      const layerNumber =
        index === activeIndex.value
          ? 0
          : Math.abs(getDistanceFromActive(index, null));
      const layerClass =
        layerNumber > maxVisible.value / 2
          ? "layer-hidden"
          : `layer-${layerNumber}`;

      return index === activeIndex.value
        ? `${layerClass} swiper-slide--active`
        : layerClass;
    };

    const getDistanceFromActive = (index, numberBeforeOrAfterActive) => {
      const numBeforeOrAfterActive =
        numberBeforeOrAfterActive || (maxVisible.value - 1) / 2;

      let distanceFromActiveIndex = index - activeIndex.value;

      const difference =
        activeIndex.value + numBeforeOrAfterActive - props.cards.length + 1;

      if (difference > 0) {
        if (difference === 1 && index === 0) {
          distanceFromActiveIndex = numBeforeOrAfterActive === 1 ? 1 : 2;
        } else {
          for (let i = 0; i < difference; i += 1) {
            if (i === index) {
              distanceFromActiveIndex = i + 1;
            }
          }
        }
      }

      const differenceBefore = activeIndex.value - numBeforeOrAfterActive;

      if (differenceBefore < 0) {
        if (differenceBefore === -1 && index === props.cards.length - 1) {
          distanceFromActiveIndex = numBeforeOrAfterActive === 1 ? -1 : -2;
        } else {
          for (
            let i = props.cards.length - 1;
            i > props.cards.length - 1 + differenceBefore;
            i -= 1
          ) {
            if (i === index) {
              distanceFromActiveIndex = i - props.cards.length;
            }
          }
        }
      }

      return distanceFromActiveIndex;
    };

    const slideStyle = (index) => {
      if (index === activeIndex.value) {
        return null;
      }

      const numBeforeOrAfterActive = (maxVisible.value - 1) / 2;
      const distanceFromActiveIndex = getDistanceFromActive(
        index,
        numBeforeOrAfterActive
      );

      // layer 2 scale is 0.84;
      const notVisibleScale = 0.7;
      const notVisibleStyle = {
        "z-index": -1,
        opacity: 0,
        transform: `
            translate3d(0,0,0)
            scale(${notVisibleScale})
            rotate(0deg)
          `,
      };

      if (
        distanceFromActiveIndex > numBeforeOrAfterActive ||
        distanceFromActiveIndex < -numBeforeOrAfterActive
      ) {
        return notVisibleStyle;
      }

      return generateSlideStyle(distanceFromActiveIndex);
    };

    const generateSlideStyle = (distanceFromActiveIndex) => {
      let style = {};

      const sign = distanceFromActiveIndex > 0 ? 1 : -1;
      const distanceFromActiveSigned = distanceFromActiveIndex * sign;
      const scale = 1 - 0.02 * (distanceFromActiveSigned * 4);

      const numBeforeOrAfterActive = (maxVisible.value - 1) / 2;
      const activeCardFoldToBoundary =
        swiperWidth.value / 2 - (itemWidth.value * scale) / 2;
      const cardFoldSize = activeCardFoldToBoundary / numBeforeOrAfterActive;

      const zindex = 10 - distanceFromActiveSigned;
      const rotate = 1.25 * distanceFromActiveIndex;
      const translateX = calculateSlideTranslate(
        cardFoldSize,
        distanceFromActiveIndex,
        sign
      );

      style = {
        "z-index": zindex,
        "-ms-transform": `rotate(${rotate}deg)`,
        transform: `
            translate3d(${translateX}px, 0, 0)
            scale(${scale})
            rotate(${rotate}deg)
          `,
      };

      return style;
    };

    const calculateSlideTranslate = (
      cardFoldSize,
      distanceFromActiveIndex,
      sign
    ) => {
      let spread = -0.04;
      const distanceFromActiveIndexUnsigned = Math.abs(distanceFromActiveIndex);

      // spread first layer more if 5 cards
      if (distanceFromActiveIndexUnsigned === 1 && maxVisible.value > 4) {
        spread = 0.22;
      }
      const variance = spread * cardFoldSize * sign;
      const slideTranslate = cardFoldSize * distanceFromActiveIndex + variance;

      return Math.round(slideTranslate);
    };

    watch(activeIndex, (newVal) => {
      emit("index-change", newVal);
    });

    return {
      flipped: props.flipped,
      cardCarousel,
      itemWidth,
      itemHeight,
      // screenSizeWatcher,
      swiperWidth,
      gridLayout,
      hammer,
      hammerSwipeOptions,
      linkHoverActive,
      iosSlideZindexTransition,
      windowResize,
      disableShine,
      cssProps,
      headingText,
      isHome,
      slideClass,
      slideStyle,
      calculateSlideTranslate,
      maxVisible,
      device,
      handleClick,
      activeIndex,
      toggleGrid,
      showTooltip,
      next,
      prev,
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  &__header {
    h3 {
      @apply text-xl;
      &:before {
        border-color: #e8e8e8;
      }
      span {
        @apply text-grey-600 font-normal;
      }
    }
    .layout-actions {
      margin-top: 0;
      top: 0.8rem;
      font-size: 1.7rem;
      .btn-layout {
        @apply text-grey-200;
        width: 2.6rem;
        height: 2.6rem;
        &.btn-layout-grid {
          font-size: 0.75em;
          &:before {
          }
        }
        &:not(.btn-layout--active) {
          &:hover {
            color: var(--blue);
          }
        }
        &--active {
          @apply text-grey-300;
          cursor: default;
        }
      }
    }
  }
  &__wrap {
    .swiper-container {
      height: var(--slide-base-height);

      .swiper-slide {
        width: var(--slide-base-width);
        height: var(--slide-base-height);
        left: calc(50% - var(--slide-base-width) / 2);
        transform: translate3d(0, 0, 0) scale(1) rotate(0deg);
        transition: var(--slide-transition);
        z-index: 4;
        &.layer-1 {
          z-index: 3;
        }
        &.layer-2 {
          z-index: 2;
        }
        &--active {
          transition: var(--slide-transition-active);
          z-index: 10;
        }
        &.layer-hidden {
          transition: var(--slide-transition-hidden);
          z-index: 0;
        }
      }
    }
  }
  &__helper {
    bottom: 0.33rem;
    > span {
      padding-top: 0rem;
    }
    .swipe-icon {
      margin-right: 0.25rem;
      animation: swipe-left-helper 12s infinite;
      .icon-arrow-left {
        left: -0.5rem;
        top: -0.15em;
      }
    }
  }
  .btn-slide {
    display: none;
  }
  .indicator-track {
    @apply flex justify-center py-2 mt-1 w-full;
    list-style: none;
    .indicator {
      @include transition-base;
      @apply rounded-full bg-grey-300 p-0 flex;
      list-style: none;
      opacity: 0.6;
      width: 6px;
      height: 6px;
      margin: 4px;
      &--active {
        transform: scale(1.2);
        opacity: 1;
      }
      button {
        &.--active {
        }
      }
    }
    .indicator:before {
      content: none !important;
    }
  }

  &--grid {
    @apply mb-4;
    .swiper__wrap {
      .swiper-container {
        @apply flex flex-wrap h-auto justify-center max-w-none;
        width: calc(100% + 1rem);
        margin-left: -0.5rem;
        .swiper-slide {
          @apply relative mx-2 mb-4;
          transform: none !important;
          left: auto !important;
          opacity: 1 !important;
          width: auto !important;
          height: auto !important;
          .card {
          }
        }
        &.max-cols-3 {
          max-width: 1000px;
        }
      }
    }
  }
}

.app--no-touch {
  .swiper {
    &__header {
      .layout-actions {
        .btn-layout {
          &:not(.btn-layout--active) {
            &:hover {
              color: var(--blue);
            }
          }
        }
      }
    }
  }
}

.schedule,
.collapsible {
  .swiper {
    &__helper {
      display: none;
    }
  }
}
.width-sm.contents,
.main-content,
.connect,
.booking-select-camp__matches {
  > .swiper {
    .btn-slide {
      @apply flex;
    }
  }
}

.no-slider-arrows {
  .swiper {
    .btn-slide {
      display: none !important;
    }
  }
}

@media screen and (max-width: 479px) {
  .swiper {
    &--grid {
      .swiper__wrap {
        .swiper-container {
          width: calc(100% + 2rem);
          margin-left: -1rem;
          .swiper-slide {
            :deep(.card) {
              font-size: 3.15vw;
              --card-border-radius: 0.929em;
              --card-frame: 0.357em;
              .card__content {
                padding: 0.5em 0.5em 0 0.75em;
                &.card__front {
                  padding-bottom: 0.5em;
                  .subheading {
                    font-size: 0.875em;
                    padding-right: 2em;
                    padding-bottom: 0.5em;
                  }
                  .meta__attributes {
                    margin-bottom: 0.1em;
                    .meta-price,
                    .meta-rooms {
                      font-size: 0.97em;
                      line-height: 1.3em;
                    }
                    .meta-attribute {
                      font-size: 1.05em;
                      &:before {
                        margin-right: 0.5em;
                      }
                    }
                    &.meta__attributes-tour,
                    &.meta__attributes-retreat {
                      .meta-attribute {
                        span {
                          line-height: 1.2;
                        }
                      }
                    }
                  }
                  &--post {
                    .subheading {
                      padding-right: 0;
                    }
                    .card-bottom {
                      margin: 0.25em 0 0.25em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@screen xs {
  .swiper {
    &__header {
      .layout-actions {
        margin-top: 0.15rem;
        font-size: 1.8rem;
      }
    }
    &--grid {
      .swiper__wrap {
        .swiper-container {
          width: calc(100% + 1rem);
          margin-left: -0.5rem;
          .swiper-slide {
            @apply mx-2 mb-4;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 480px) and (max-width: 639px) {
  .swiper {
    &--grid {
      .swiper__wrap {
        .swiper-container {
          .swiper-slide {
            .card {
              font-size: 0.96em;
            }
          }
        }
      }
    }
  }
}
@screen md {
  .swiper {
    &__header {
      .layout-actions {
        margin-top: 0.1rem;
        font-size: 2rem;
        .btn-layout {
          width: 2.8rem;
          height: 2.8rem;
        }
      }
    }
    .indicator-track {
      @apply mt-4;
      .indicator {
        width: 7px;
        height: 7px;
      }
    }
    &--grid {
      .swiper__wrap {
        .swiper-container {
          width: calc(100% + 1.5rem);
          margin-left: -0.75rem;
          .swiper-slide {
            @apply relative mb-6 mx-3;
          }
        }
      }
    }
  }
}
@screen lg {
  .swiper {
    &__header {
      .layout-actions {
        margin-top: 0.2rem;
        font-size: 2rem;
      }
    }
    &--grid {
      .swiper__wrap {
        .swiper-container {
          @apply w-full mx-auto;
          .swiper-slide {
            @apply mb-8 mx-4;
          }
        }
      }
    }
  }
  .width-sm.contents,
  .booking-page .booking-select-camp__matches {
    > .swiper {
      .swiper__wrap {
        width: calc(100vw - 6rem);
        margin-left: calc(-100vw / 2 + 768px / 2 + 3rem);
        margin-right: calc(-100vw / 2 + 768px / 2 + 3rem);
      }
    }
  }
}
@media screen and (min-width: 1414px) {
  .width-sm.contents,
  .booking-page .booking-select-camp__matches {
    > .swiper {
      .swiper__wrap {
        width: 1280px;
        margin-left: calc(-1280px / 2 + 768px / 2);
        margin-right: calc(-1280px / 2 + 768px / 2);
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1120px) {
  .app__content {
    .swiper {
      .btn-slide {
        display: none !important;
      }
    }
  }
}
@screen xl {
  .swiper {
    &__header {
      h3 {
        font-size: 1.5rem;
      }
      .layout-actions {
        margin-top: 0.4rem;
      }
    }
  }
}
</style>
