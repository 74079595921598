import axios from "axios";
import store from "../store";

export const ENDPOINT = "https://cms.londolozi.com/wp-json/wp/v2";
export const apiClient = axios.create();

export function NoMatchingSlugError() { }
NoMatchingSlugError.prototype = new Error();

export const resourceTypes = {
  pages: "pages",
  camps: "camps",
  tours: "tours",
  retreats: "retreats",
};

export const pageIdMapping = {
  home: 256,
  about: 258,
  camps: 274,
  experience: 265,
  safari: 2687,
  tours: 286,
  retreats: 287,
  destinations: 2260,
  footer: 270,
  connect: 268,
  compare: 300,
  booking: 1943,
  impact: 2763,
  notFound: 1008,
  rates: 2546,
  bookingSuccess: 2742,
  enquire: 345,
};

export const indexPageIdToSinglePage = {
  [pageIdMapping.camps]: "CampSinglePage",
  [pageIdMapping.about]: "AboutSinglePage",
  [pageIdMapping.experience]: "ExperienceSinglePage",
  [pageIdMapping.safari]: "SafariSinglePage",
  [pageIdMapping.tours]: "TourSinglePage",
  [pageIdMapping.retreats]: "RetreatPage",
};

export const mapSlugToPage = (slug, pageMap = {}) => {
  let pageMapping = pageMap;
  if (process.client) {
    let { pageMapping: thePageMap } = store.state.global;
    pageMapping = thePageMap;
  }
  const pageMappingEntries = Object.entries(pageMapping || {});

  for (let i = 0; i < pageMappingEntries.length; i += 1) {
    const [key, value] = pageMappingEntries[i];
    if (value.slug === slug) {
      return { ...pageMapping[key], id: key };
    }
  }

  throw new NoMatchingSlugError();
};

export const getResource = (resourceType, resourceId) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  apiClient.get(`${ENDPOINT}/${resourceType}/${resourceId}`);

export const getRates = () => apiClient.get("https://cms.londolozi.com/wp-json/londolozi-cms/v1/rates");
export const getOutline = () =>
  apiClient.get("https://cms.londolozi.com/wp-json/londolozi-cms/v1/outline");
export const getHome = () =>
  getResource(resourceTypes.pages, pageIdMapping.home);
export const getAbout = () =>
  getResource(resourceTypes.pages, pageIdMapping.about);
export const getCamps = () =>
  getResource(resourceTypes.camps, pageIdMapping.camps);
export const getRatesPage = () =>
  getResource(resourceTypes.pages, pageIdMapping.rates);
export const getExperience = () =>
  getResource(resourceTypes.pages, pageIdMapping.experience);
export const getSafari = () =>
  getResource(resourceTypes.pages, pageIdMapping.safari);
export const getTours = () =>
  getResource(resourceTypes.tours, pageIdMapping.tours);
export const getRetreats = () =>
  getResource(resourceTypes.retreats, pageIdMapping.retreats);
export const getFooter = () =>
  getResource(resourceTypes.pages, pageIdMapping.footer);
export const getConnect = () =>
  getResource(resourceTypes.pages, pageIdMapping.connect);
export const getCompare = () =>
  getResource(resourceTypes.camps, pageIdMapping.compare);
export const getBooking = () =>
  getResource(resourceTypes.pages, pageIdMapping.booking);
export const getImpactPage = () =>
  getResource(resourceTypes.pages, pageIdMapping.impact);
export const getEnquirePage = () =>
  getResource(resourceTypes.pages, pageIdMapping.enquire);
export const getDestinations = () =>
  getResource(resourceTypes.pages, pageIdMapping.destinations);
export const getNotFound = () =>
  getResource(resourceTypes.pages, pageIdMapping.notFound);
export const getSinglePage = (slug, pageMapping = {}) => {
  const mappedSlugPage = mapSlugToPage(slug, pageMapping);

  return getResource(`${mappedSlugPage.type}s`, mappedSlugPage.id);
};
// eslint-disable-next-line max-len
export const getBookingSuccess = () =>
  getResource(resourceTypes.pages, pageIdMapping.bookingSuccess);
