import { default as _404nUKSqqvXBMMeta } from "/build/pages/404.vue?macro=true";
import { default as thank_45yougyiaPgeH9yMeta } from "/build/pages/booking/thank-you.vue?macro=true";
import { default as _91slug_93PeCwmzAL08Meta } from "/build/pages/en/about/[slug].vue?macro=true";
import { default as indexsKFxbL2Vk8Meta } from "/build/pages/en/about/index.vue?macro=true";
import { default as _91slug_93lSsKYVFjmKMeta } from "/build/pages/en/accommodation/[slug].vue?macro=true";
import { default as compare_45campseNe18aFOl1Meta } from "/build/pages/en/accommodation/compare-camps.vue?macro=true";
import { default as indexKGpRXNc8JZMeta } from "/build/pages/en/accommodation/index.vue?macro=true";
import { default as rates7uwxy4AUCPMeta } from "/build/pages/en/accommodation/rates.vue?macro=true";
import { default as _91slug_93SHjIXp2OE6Meta } from "/build/pages/en/contact/[slug].vue?macro=true";
import { default as indexmTIHmFdMaKMeta } from "/build/pages/en/contact/index.vue?macro=true";
import { default as _91slug_93nRfj91u0m7Meta } from "/build/pages/en/destinations/[slug].vue?macro=true";
import { default as index06nl91mlxeMeta } from "/build/pages/en/destinations/index.vue?macro=true";
import { default as enquirenqhsLu07eUMeta } from "/build/pages/en/enquire.vue?macro=true";
import { default as _91slug_93EUQJ7ABuu5Meta } from "/build/pages/en/experience/[slug].vue?macro=true";
import { default as indexFXsVaL658xMeta } from "/build/pages/en/experience/index.vue?macro=true";
import { default as _91slug_93X2xuqR4UW0Meta } from "/build/pages/en/impact/[slug].vue?macro=true";
import { default as indexJuxwXG329vMeta } from "/build/pages/en/impact/index.vue?macro=true";
import { default as indexL8KSJFPJH5Meta } from "/build/pages/en/index.vue?macro=true";
import { default as _91slug_93TQWgNbc8N6Meta } from "/build/pages/en/retreats/[slug].vue?macro=true";
import { default as indexzlA5FQZC47Meta } from "/build/pages/en/retreats/index.vue?macro=true";
import { default as _91slug_93faRjvV2jTWMeta } from "/build/pages/en/safari/[slug].vue?macro=true";
import { default as indexvBU978mNlgMeta } from "/build/pages/en/safari/index.vue?macro=true";
import { default as _91slug_933DOx0ZSzxdMeta } from "/build/pages/en/tours/[slug].vue?macro=true";
import { default as indexOZctHqJjd6Meta } from "/build/pages/en/tours/index.vue?macro=true";
import { default as index2yY1hMMS9YMeta } from "/build/pages/index.vue?macro=true";
export default [
  {
    name: "404___en",
    path: "/404",
    component: () => import("/build/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "booking-thank-you___en",
    path: "/booking/thank-you",
    component: () => import("/build/pages/booking/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "en-about-slug___en",
    path: "/en/about/:slug()",
    component: () => import("/build/pages/en/about/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-about___en",
    path: "/en/about",
    component: () => import("/build/pages/en/about/index.vue").then(m => m.default || m)
  },
  {
    name: "en-accommodation-slug___en",
    path: "/en/accommodation/:slug()",
    component: () => import("/build/pages/en/accommodation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-accommodation-compare-camps___en",
    path: "/en/accommodation/compare-camps",
    component: () => import("/build/pages/en/accommodation/compare-camps.vue").then(m => m.default || m)
  },
  {
    name: "en-accommodation___en",
    path: "/en/accommodation",
    component: () => import("/build/pages/en/accommodation/index.vue").then(m => m.default || m)
  },
  {
    name: "en-accommodation-rates___en",
    path: "/en/accommodation/rates",
    component: () => import("/build/pages/en/accommodation/rates.vue").then(m => m.default || m)
  },
  {
    name: "en-contact-slug___en",
    path: "/en/contact/:slug()",
    component: () => import("/build/pages/en/contact/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-contact___en",
    path: "/en/contact",
    component: () => import("/build/pages/en/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "en-destinations-slug___en",
    path: "/en/destinations/:slug()",
    component: () => import("/build/pages/en/destinations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-destinations___en",
    path: "/en/destinations",
    component: () => import("/build/pages/en/destinations/index.vue").then(m => m.default || m)
  },
  {
    name: "en-enquire___en",
    path: "/en/enquire",
    component: () => import("/build/pages/en/enquire.vue").then(m => m.default || m)
  },
  {
    name: "en-experience-slug___en",
    path: "/en/experience/:slug()",
    component: () => import("/build/pages/en/experience/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-experience___en",
    path: "/en/experience",
    component: () => import("/build/pages/en/experience/index.vue").then(m => m.default || m)
  },
  {
    name: "en-impact-slug___en",
    path: "/en/impact/:slug()",
    component: () => import("/build/pages/en/impact/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-impact___en",
    path: "/en/impact",
    component: () => import("/build/pages/en/impact/index.vue").then(m => m.default || m)
  },
  {
    name: "en___en",
    path: "/en",
    component: () => import("/build/pages/en/index.vue").then(m => m.default || m)
  },
  {
    name: "en-retreats-slug___en",
    path: "/en/retreats/:slug()",
    component: () => import("/build/pages/en/retreats/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-retreats___en",
    path: "/en/retreats",
    component: () => import("/build/pages/en/retreats/index.vue").then(m => m.default || m)
  },
  {
    name: "en-safari-slug___en",
    path: "/en/safari/:slug()",
    component: () => import("/build/pages/en/safari/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-safari___en",
    path: "/en/safari",
    component: () => import("/build/pages/en/safari/index.vue").then(m => m.default || m)
  },
  {
    name: "en-tours-slug___en",
    path: "/en/tours/:slug()",
    component: () => import("/build/pages/en/tours/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-tours___en",
    path: "/en/tours",
    component: () => import("/build/pages/en/tours/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/build/pages/index.vue").then(m => m.default || m)
  }
]