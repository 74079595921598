import imageConstants from "@/util/imageConstants";

export default {
  state: {
    dataLoading: true,
    pageMapping: null,
    campRates: [],
    footer: null,
    images: [
      {
        imageName: "leopard-watermark",
        url: imageConstants.leopardWatermark,
        loaded: false,
        ignoreDuringRenderLoad: true,
      },
      {
        imageName: "header-logo-text",
        url: imageConstants.logo.header.text,
        loaded: false,
        ignoreDuringRenderLoad: true,
      },
      {
        imageName: "card-pattern",
        url: imageConstants.pattern.card,
        loaded: false,
        ignoreDuringRenderLoad: true,
      },
      {
        imageName: "logo-svg-white",
        url: imageConstants.logo.svg.white,
        loaded: false,
        ignoreDuringRenderLoad: true,
      },
      {
        imageName: "pattern-overlay-banner",
        url: imageConstants.pattern.banner,
        loaded: false,
        ignoreDuringRenderLoad: true,
      },
    ],
  },

  getters: {
    allImages: (state) => state.images,

    imageByName: (state) => (name) =>
      state.images.find((img) => img.imageName === name),

    isLoading: (state) => {
      // eslint-disable-next-line operator-linebreak
      /*
      const imagesLoading =
        state.images.filter((image) => !image.loaded && !image.ignoreDuringRenderLoad)
          .length !== 0;
      */

      //return imagesLoading || state.dataLoading;
      return state.dataLoading;
    },

    parentIdById: (state) => (id) => state.pageMapping[id].parent,
  },

  mutations: {
    setPageMapping: (state, payload) => {
      state.pageMapping = payload;
      return state;
    },

    setCampRates: (state, payload) => {
      state.campRates = payload;
      return state;
    },

    setFooter: (state, payload) => {
      state.footer = payload;
      return state;
    },

    setImages: (state, payload) => {
      state.images = payload;
      return state;
    },

    addImage: (state, payload) => {
      state.images = [...state.images, payload];

      return state;
    },

    updateImage: (state, payload) => {
      state.images = [
        ...state.images.filter((img) => img.imageName !== payload.imageName),
        payload,
      ];

      return state;
    },

    setDataLoading: (state, payload) => {
      state.dataLoading = payload;
      return state;
    },
  },

  actions: {
    setOutline: ({ commit, dispatch }, payload) => {
      const cards = Object.values(payload)
        .filter((outlineEntry) => outlineEntry.card)
        .map((outlineEntry) => {
          if (outlineEntry.children) {
            return {
              ...outlineEntry.card,
              children: outlineEntry.children,
            };
          }

          return outlineEntry.card;
        });

      commit("setCards", cards, { root: true });
      dispatch("extractCardsAndSetPageMapping", payload);
    },

    extractCardsAndSetPageMapping: ({ commit }, payload) => {
      const payloadDup = { ...payload };
      const payloadVals = Object.values(payloadDup);

      for (let i = 0; i < payloadVals.length; i += 1) {
        delete payloadVals[i].card;
      }

      commit("setPageMapping", payloadDup);
    },

    updateImage: ({ getters, commit }, payload) => {
      const statefulImage = getters.imageByName(payload.imageName);
      Object.assign(statefulImage, payload);

      commit("updateImage", statefulImage);
    },
  },
};
