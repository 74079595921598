import FloatingVue from "floating-vue";

const options = {
  themes: {
    tooltip: {
      distance: 10,
      triggers: screen.touch ? ["touch"] : ["hover"],
      delay: screen.touch ? { show: 20, hide: 1000 } : { show: 20, hide: 20 },
      container: ".app",
    },
    "transparent-tooltip": {
      $extend: "tooltip",
    },
    "note-tooltip": {
      $extend: "tooltip",
      html: true,
      skidding: 84,
      delay: screen.touch ? { show: 20, hide: 3000 } : { show: 20, hide: 600 },
    },
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(FloatingVue, options);
});
