<!-- eslint-disable max-len -->
<template>
  <div
    v-if="cardData"
    class="card relative"
    :class="[
      { 'card--flipped': flipped },
      { 'card--disabled': preventEvents },
      `card-${cardData.type}`,
      { 'card--link-hovered': cardLinkHovered },
      { 'card--prevent-nav': preventNavigation },
    ]"
  >
    <div class="card__inner absolute w-full h-full">
      <div class="card__face card-front z-10">
        <div class="card__background">
          <img
            class="pointer-events-none"
            :src="cardImageSrc"
            :alt="cardData.title"
          />
        </div>
        <div class="card__pattern absolute w-full h-full bottom-0 right-0 flex">
          <img :src="constants.images.pattern.card" class="mt-auto" alt="" />
        </div>
        <div class="card__contents">
          <CardFront
            :cardContent="cardData"
            :type="cardData.type"
            :href="href"
            :linkHoverActive="linkHoverActive"
            @navigate="maybeNavigate"
            @cardLinkHovered="cardLinkHover"
          />
        </div>
        <div v-if="cardNumber" class="card__number-bg"></div>
        <div v-if="cardNumber" class="card__number-number">
          {{ cardNumber }}
        </div>
        <button v-if="renderBack" v-on:click="flipCard" class="btn-flip">
          <i class="icon icon-flip"></i>
        </button>
        <div class="card__shine"></div>
      </div>

      <div class="card__face card-back z-20" v-if="renderBack">
        <div class="card__background">
          <img
            class="pointer-events-none"
            :src="cardImageSrc"
            :alt="cardData.title"
          />
        </div>
        <div class="card__pattern absolute w-full h-full bottom-0 right-0 flex">
          <img :src="constants.images.pattern.card" class="mt-auto" alt="" />
        </div>
        <div class="card__contents">
          <CardBack
            :cardContent="cardData"
            @navigate="maybeCardBackNavigate"
            @cardLinkHovered="cardLinkHover"
            :type="cardData.type"
            :href="href"
          />
        </div>
        <div v-if="cardNumber" class="card__number-bg"></div>
        <div v-if="cardNumber" class="card__number-number">
          {{ cardNumber }}
        </div>
        <button v-if="renderBack" v-on:click="flipCard" class="btn-flip">
          <i class="icon icon-flip"></i>
        </button>
        <div class="card__shine"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed } from "vue";
import store from "@/store";
import CardFront from "@/components/Cards/CardFront.vue";
import CardBack from "@/components/Cards/CardBack.vue";
import {
  parentIdToChildRouteName,
  parentIdToIndexRouteName,
  routeNameToPath,
  slugIsConstant,
} from "@/util/pageMapping";
import { CONSTANTS as constants } from "@/util/constants";

export default {
  name: "LondoCard",
  components: {
    CardFront,
    CardBack,
  },
  props: {
    card: {
      type: [Object, Number],
      required: true,
    },
    index: {
      type: Number,
    },
    count: {
      type: Number,
    },
    hasBack: {
      type: Boolean,
      default: true,
      required: false,
    },
    initialFlipped: {
      type: Boolean,
      default: false,
      required: false,
    },
    preventEvents: {
      type: Boolean,
      default: false,
    },
    preventNavigation: {
      type: Boolean,
      default: false,
    },
    linkHoverActive: {
      type: Boolean,
      default: true,
    },
    hideNumber: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const currentRoute = useRoute();
    const router = useRouter();

    const cardById = (id) => {
      return computed(() => store.getters.cardById(id)).value;
    };

    const parentIdById = (id) => {
      return computed(() => store.getters.parentIdById(id)).value;
    };

    const childCardsIdsForIndexId = (id) => {
      return computed(() => store.getters.childCardsIdsForIndexId(id)).value;
    };

    const flipped = ref(props.initialFlipped);
    const cardLinkHovered = ref(false);

    const cardData = computed(() => {
      if (!store.state.cards?.list) {
        return;
      }

      return typeof props.card === "object"
        ? props.card
        : store.getters.cardById(props.card);
    });

    const renderBack = computed(() => {
      return props.hasBack;
    });

    const cardImageSrc = computed(() => {
      return cardData.value.image || constants.images.fallback.card;
    });

    const isPost = computed(() => {
      return cardData.value.type === "post";
    });

    const route = computed(() => {
      if (isPost.value) return {};

      const { pageMapping } = store.state.global;
      const { slug, parent, children } = pageMapping[cardData.value.id];

      let routeName;
      let routePath;

      const slugConstant = slugIsConstant(slug);

      if (slugConstant) {
        routeName = slugConstant.routeName;
        routePath = slugConstant.routePath;
      } else {
        routeName = children
          ? parentIdToIndexRouteName[cardData.value.id]
          : parentIdToChildRouteName[parent];

        routePath = routeNameToPath[routeName];
      }

      const isIndex = Boolean(children);

      return {
        isIndex,
        routePath,
        routeName,
        slug,
      };
    });

    const href = computed(() => {
      if (isPost.value) {
        return cardData.value.link;
      }

      return route.value.isIndex
        ? `/en/${route.value.routePath}`
        : `/en/${route.value.routePath}/${route.value.slug}`;
    });

    const cardNumber = computed(() => {
      if (isPost.value || props.hideNumber) {
        return false;
      }

      if (props.index && props.index >= 0) {
        return `${props.index}/${props.count}`;
      }

      const parentId = parentIdById(cardData.value.id);
      if (parentId && parentId !== 0) {
        const cardsInSection = childCardsIdsForIndexId(parentId);
        const cardNumber = cardsInSection.indexOf(cardData.value.id) + 1;
        return `${cardNumber}/${cardsInSection.length}`;
      }
      return false;
    });

    // Methods
    const flipCard = () => {
      if (props.preventEvents) return;
      flipped.value = !flipped.value;
    };

    const cardLinkHover = (val) => {
      cardLinkHovered.value = val;
    };

    const maybeNavigate = () => {
      if (props.preventEvents || props.preventNavigation) return;
      navigateToCardResource();
    };

    const maybeCardBackNavigate = () => {
      if (props.preventEvents) return;
      navigateToCardResource();
    };

    const navigateToCardResource = () => {
      const currentRoute = router.currentRoute;

      if (
        currentRoute.value.name === route.value.routeName &&
        currentRoute.value.params.slug === route.value.slug
      ) {
        return;
      }
      router.push(href.value);
    };

    return {
      flipped,
      cardLinkHovered,
      cardData,
      cardImageSrc,
      isPost,
      route,
      href,
      cardNumber,
      flipCard,
      cardLinkHover,
      maybeNavigate,
      maybeCardBackNavigate,
      navigateToCardResource,
      constants,
      renderBack,
    };
  },
};
</script>

<style lang="scss" scoped>
.app--no-touch {
  .card {
    .btn-flip {
      &:hover {
        i {
          transform: rotate3d(0, 1, 0, 180deg);
        }
      }
    }
  }
}
.card {
  @apply my-0 leading-normal;
  margin: 0 auto;
  text-shadow: 0 1 3px rgba(0, 0, 0, 0.85);
  transition: transform 0.3s ease;
  .btn-flip {
    @apply z-20 absolute top-0 right-0 text-white p-2 leading-tight;
    @include text-shadow-card;
    margin: var(--card-frame);
    transform: translate3d(0, 0, 0);
    i {
      @apply flex;
      font-size: 1.5em;
      transition: 0.3s ease;
    }
  }
  perspective: 800px;
  &__inner {
    transform-style: preserve-3d;
    transition: transform 0.7s, box-shadow 0.5s ease;
    border-radius: var(--card-border-radius);
    box-shadow: 0 0.5em 1.3em rgba(0, 0, 0, 0.35),
      0 1px 2px 0px rgba(0, 0, 0, 0.9);
  }
  &__face {
    @apply overflow-hidden w-full h-full absolute;
    backface-visibility: hidden;
    padding: var(--card-frame);
    border-radius: var(--card-border-radius);
    &:after {
      @apply absolute inset-0 pointer-events-none;
      border-radius: var(--card-border-radius);
      transition: opacity var(--slide-transition-duration) ease;
      content: "";
      background: white;
      z-index: 20;
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
    &.card-front {
      .card__contents {
        box-shadow: inset 0 0 0.5em 0 rgba(0, 0, 0, 0.1),
          0 0 0.1em 0 rgba(0, 0, 0, 0.2), inset 0 -3em 6em rgba(0, 0, 0, 0.2);
      }
    }
    &.card-back {
      transform: rotateY(-180deg);
      background: white;
      .card__background {
        transform: rotateY(-180deg);
        opacity: 0.65;
      }
      .card__pattern {
        @apply pointer-events-none;
        mix-blend-mode: difference;
        opacity: 0.5;
        transform: translate3d(0, 0, 0);
        z-index: 15;
      }
      .card__number-bg {
        background: rgba(0, 0, 0, 0.7);
      }
      .card__contents {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &__background {
    @apply inset-0 absolute z-0;
    background-color: white;
    border-radius: var(--card-border-radius);
    overflow: hidden;
    img {
      @apply w-full h-full object-cover;
      transition: 0.3s ease-out;
    }
    &:after {
      @apply absolute inset-0;
      content: "";
      border-radius: var(--card-border-radius);
      box-shadow: inset 0 0 0.6rem 0 rgba(0, 0, 0, 0.1),
        inset 0 0 3px 0px rgba(255, 255, 255, 0.2),
        inset 0 0 0 1px rgba(0, 0, 0, 0.05);
      background: radial-gradient(
          ellipse at center,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.03) 100%
        ),
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0) 18%,
          rgba(0, 0, 0, 0) 45%,
          rgba(0, 0, 0, 0.2) 70%,
          rgba(0, 0, 0, 0.6) 100%
        );
    }
  }
  &__pattern {
    @apply pointer-events-none;
    padding: var(--card-frame);
  }
  &__number-bg {
    @apply absolute top-0 z-10 pointer-events-none;
    transform: translate3d(-50%, 0, 0);
    border-radius: 0 0 1.7em 1.7em;
    background: rgba(0, 0, 0, 0.6);
    font-size: 0.75em;
    width: 3.2em;
    left: 50%;
    height: calc(1.7em + var(--card-frame) / 2);
  }
  &__number-number {
    @apply font-din text-center text-white absolute top-0 z-20 pointer-events-none;
    font-size: 0.75em;
    padding-top: calc(0.6em - var(--card-frame));
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    @include text-shadow-card;
  }
  &__shine {
    @include shine;
  }
  &__contents {
    @apply h-full w-full flex flex-col z-10 relative;
    border-radius: calc(0.7 * var(--card-border-radius));
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 0 0.5em 0 rgba(0, 0, 0, 0.1),
      0 0 0.1em 0 rgba(0, 0, 0, 0.2);
  }
  &--flipped {
    .card__inner {
      transform: rotateY(180deg);
    }
  }
  &--disabled {
    cursor: grab;
    .card__inner {
      @apply pointer-events-none;
    }
  }
  &:not(.card--swiper) {
    @apply mx-auto mb-12;
  }
  &--link-hovered {
    .card__inner {
      box-shadow: 0 0.5em 2.5em rgba(0, 0, 0, 0.3),
        0 1px 2px 0px rgba(0, 0, 0, 0.9);
      .card-front {
        .card__background {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
.app--drawer-open {
  .main-content {
    .card__shine {
      display: none !important;
      animation: none;
    }
  }
}
.app:not(.app--booking-open) {
  .booking {
    .card__shine {
      display: none !important;
      animation: none;
    }
  }
}
.swiper-slide {
  .card {
    &__inner {
      transition: transform 0.7s,
        box-shadow var(--slide-transition-duration) ease;
    }
  }
  &.layer-1:not(.slide-grid) {
    .card {
      &__inner {
        box-shadow: 0 0.3em 0.75em rgba(0, 0, 0, 0.3),
          0 1px 2px 0px rgba(0, 0, 0, 0.9);
      }
      &__shine {
        opacity: 0.1;
      }
      &__face {
        &:after {
          opacity: 0.15;
        }
      }
    }
  }
  &.layer-2:not(.slide-grid) {
    .card {
      &__inner {
        box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.275),
          0 1px 2px 0px rgba(0, 0, 0, 0.9);
      }
      &__face {
        &:after {
          opacity: 0.3;
        }
      }
      &__shine {
        opacity: 0.05;
      }
    }
  }
  &.layer-hidden:not(.slide-grid) {
    .card {
      &__inner {
        box-shadow: 0 0.25em 0.2em rgba(0, 0, 0, 0);
      }
      &__shine {
        opacity: 0;
      }
    }
  }
  &.slide-grid {
    .card {
      &__inner {
        box-shadow: 0 0.3em 0.75em rgba(0, 0, 0, 0.3),
          0 1px 2px 0px rgba(0, 0, 0, 0.9);
      }
      &--link-hovered {
        .card__inner {
          box-shadow: 0 0.3em 2.1em rgba(0, 0, 0, 0.2),
            0 1px 2px 0px rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
}

@screen sm {
  .swiper-slide {
    &.layer-1:not(.slide-grid) {
      .card {
        &__face {
          &:after {
            opacity: 0.1;
          }
        }
      }
    }
    &.layer-2:not(.slide-grid) {
      .card {
        &__face {
          &:after {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

@screen md {
  .card {
    &__face {
      padding: var(--card-frame);
    }
  }
}

@screen xl {
  .card {
    &__face {
    }
  }
}

/* SHINE */

.booking {
  .swiper-slide {
    &.layer-1:not(.slide-grid) {
      .card {
        &__shine {
          display: none !important;
          animation: none;
        }
      }
    }
    &.layer-2:not(.slide-grid) {
      .card {
        &__shine {
          display: none !important;
          animation: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .swiper-slide {
    &.slide-grid,
    &.layer-1,
    &.layer-2,
    &.layer-hidden {
      .card {
        &__shine {
          @include shine-disabled;
        }
      }
    }
  }
}

.cards {
  &--no-shine {
    .card {
      &__shine {
        @include shine-disabled;
      }
    }
  }
}

.ios,
.ipad {
  .card {
    &__shine {
      @include shine-disabled;
    }
  }
}
</style>
