const deviceDetector = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  return {
    ios: /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream,
    iphone: /iPhone/.test(userAgent) && !window.MSStream,
    ipad: /iPad/.test(userAgent) && !window.MSStream,
    android: /Android/.test(userAgent),
    androidPhone: /Android/.test(userAgent) && /Mobile/.test(userAgent),
    windows: /Windows/.test(userAgent),
    mobile: /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(userAgent),
  };
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide("device", () => deviceDetector());
});
