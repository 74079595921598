<template>
  <div
    class="collapsible mb-4"
    :class="[{ 'collapsible--open': isOpen }, { 'collapsible--lazy': lazy }]"
  >
    <button
      class="collapsible__heading italic text-grey w-full text-left leading-normal bullet relative"
      @click="toggle"
    >
      {{ title }}
    </button>
    <collapse-transition>
      <div v-show="isOpen" class="collapsible__content">
        <div class="collapsible__content__spacer h-6"></div>
        <template v-if="!lazy || isOpen">
          <div
            v-if="!contents"
            class="collapsible__content__content left-line drop-cap-child my-child-0"
          >
            <slot></slot>
          </div>
          <div
            v-else
            v-html="contents"
            class="collapsible__content__content left-line drop-cap-child my-child-0"
          ></div>
        </template>
        <div class="collapsible__content__spacer h-4"></div>
      </div>
    </collapse-transition>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import CollapseTransition from "@/components/Utilities/CollapseTransition.vue";

const props = defineProps({
  title: {
    type: String,
  },
  contents: {
    type: String,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
});

const title = ref(props.title);
const contents = ref(props.contents);
const isOpen = ref(false);

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>

<style lang="scss">
.collapsible {
  &__heading {
    padding: 0.5em 2.2rem 0.5em 1.8rem;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    &.bullet:before {
      @apply h-full;
      padding-top: 0.7em;
      top: 0;
      left: -0.2em;
      color: #555;
    }
    &:after {
      @include font-icon;
      @apply absolute text-grey-700 text-2xl;
      top: 0.3em;
      right: 0;
      content: "\62";
      transition: 0.2s;
    }
  }
  &.collapsible--open {
    .collapsible__heading {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  &__content {
    &__content {
      > div:not([class]):last-child {
        @include my-child-0;
      }
      > img {
        @apply mb-4;
      }
    }
  }
}

.contents-rendered {
  .collapsible {
    &__heading {
      padding: 0.5em 3rem 0.5em 1.8rem;
      &:after {
        @apply absolute text-2xl border border-grey-200 flex items-center justify-center;
        box-shadow: 0px 3px 17px 0.18px rgba(0, 0, 0, 0.08);
        background: rgba(255, 255, 255, 0.4);
        width: 1.66em;
        height: 1.66em;
        top: 0;
        right: 0;
        padding-top: 0.1em;
      }
    }
  }
}
.app--no-touch {
  .contents-rendered {
    .collapsible {
      &__heading {
        &:hover {
          &:after {
            @apply border-black;
            box-shadow: 0px 5px 20px 0.18px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
</style>
