import { currencyCodesMap } from "@/util/exchangeRates";

export function useFormatPrice() {
  const formatPrice = (price) => {
    const priceFloat = parseInt(price, 10);
    return priceFloat.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const formatPriceWithCurrencyCode = (val, currencyCode = "ZAR") => {
    if (!val) return "";
    return `${currencyCodesMap[currencyCode]}${formatPrice(val)}`;
  };

  return {
    formatPrice,
    formatPriceWithCurrencyCode
  };
}

