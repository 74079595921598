const enquiryActions = {
  reset: "RESET_FILTERS",
  general: {
    contact: {
      firstName: "SET_GENERAL_FIRST_NAME",
      lastName: "SET_GENERAL_LAST_NAME",
      email: "SET_GENERAL_EMAIL",
      contactNumber: "SET_GENERAL_CONTACT",
      privacyAccepted: "SET_GENERAL_PRIVACY_ACCEPTED",
      message: "SET_GENERAL_MESSAGE",
    },
  },
  agent: {
    contact: {
      firstName: "SET_AGENT_FIRST_NAME",
      lastName: "SET_AGENT_LAST_NAME",
      email: "SET_AGENT_EMAIL",
      companyName: "SET_COMPANY_NAME",
      contactNumber: "SET_AGENT_CONTACT",
      privacyAccepted: "SET_AGENT_PRIVACY_ACCEPTED",
      message: "SET_AGENT_MESSAGE",
      valid: "SET_AGENT_CONTACT_VALID",
    },
  },
  newsletter: {
    contact: {
      firstName: "SET_NEWSLETTER_FIRST_NAME",
      lastName: "SET_NEWSLETTER_LAST_NAME",
      email: "SET_NEWSLETTER_EMAIL",
      daily: "SET_NEWSLETTER_DAILY",
      monthly: "SET_NEWSLETTER_MONTHLY",
    },
  },
  retreat: {
    contact: {
      firstName: "SET_RETREAT_FIRST_NAME",
      lastName: "SET_RETREAT_LAST_NAME",
      email: "SET_RETREAT_EMAIL",
      message: "SET_RETREAT_MESSAGE",
      privacyAccepted: "SET_RETREAT_PRIVACY_ACCEPTED",
    },
  },
  booking: {
    stepIndex: "SET_BOOKING_STEP_INDEX",
    filters: {
      arrival: "SET_BOOKING_ARRIVAL",
      departure: "SET_BOOKING_DEPARTURE",
      adults: "SET_BOOKING_ADULTS",
      children: "SET_BOOKING_CHILDREN",
      children1216: "SET_BOOKING_CHILDREN1216",
      beenBefore: "SET_BOOKING_BEEN_BEFORE",
      valid: "SET_BOOKING_FILTERS_VALID",
    },
    contact: {
      firstName: "SET_BOOKING_FIRST_NAME",
      lastName: "SET_BOOKING_LAST_NAME",
      email: "SET_BOOKING_EMAIL",
      contactNumber: "SET_BOOKING_CONTACT",
      privacyAccepted: "SET_BOOKING_PRIVACY_ACCEPTED",
      valid: "SET_BOOKING_CONTACT_VALID",
    },
    extra: {
      referred: "SET_BOOKING_EXTRA_REFERRED",
      travelAgent: "SET_BOOKING_EXTRA_TRAVEL_AGENT",
      message: "SET_BOOKING_EXTRA_MESSAGE",
      daily: "SET_BOOKING_EXTRA_DAILY",
      monthly: "SET_BOOKING_EXTRA_MONTHLY",
      budget: "SET_BOOKING_EXTRA_BUDGET",
      bookedFlights: "SET_BOOKING_EXTRA_FLIGHTS",
    },
  },
};

export default enquiryActions;
