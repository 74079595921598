export default {
  state: {},

  mutations: {
    setResourceFaqs: (state, { type, payload }) => {
      state[type] = payload;
      return state;
    },
  },

  getters: {
    faqsByType: (state) => (type) => state[type] && state[type].faqs,
  },
};
