import { defineNuxtPlugin } from '#app';
import { useRouter } from 'vue-router';

export default defineNuxtPlugin(() => {
  const router = useRouter();

  router.afterEach((to, from) => {
    window.dataLayer.push({
      event: 'page_view',
      page_location: `${window.location.origin}${to.fullPath}`,
      page_path: to.fullPath,
      page_referrer: `${window.location.origin}${from.fullPath}`,
      page_title: document.title,
      page_category: to.name,
    });
  });
});
